<script lang="ts">
	import { Select } from 'bits-ui'
	import { Field, Control, Label, FieldErrors } from 'formsnap'
	import { ChevronDown, Check, Info, LoaderCircle } from 'lucide-svelte'
	import { superForm, defaults } from 'sveltekit-superforms'
	import { zodClient, zod } from 'sveltekit-superforms/adapters'
	import { z } from 'zod'
	import {
		getHeatLossModalState,
		getMarketplaceDataState,
		getHeatLossModalOpenState
	} from '$lib/stores'
	import { flyAndScale } from '$lib/transitions'
	import { t } from '$lib/translations'
	import { setCookie, getCityNameFromZip } from '$lib/utils'
	import { onMount } from 'svelte'

	const houseHeatLossModalState = getHeatLossModalState()
	const heatLossModalOpenState = getHeatLossModalOpenState()
	const marketplaceDataState = getMarketplaceDataState()

	const roofOptions = {
		null: t('web.forms.choose'),
		'0': '0 cm',
		'5': '5 cm',
		'10': '10 cm',
		'15': '15 cm',
		'20': '20 cm',
		'30': '30 cm',
		'40': '40 cm',
		'50': '50 cm'
	}

	const facadeOptions = {
		null: t('web.forms.choose'),
		'0': '0 cm',
		'5': '5 cm',
		'10': '10 cm',
		'15': '15 cm',
		'20': '20 cm',
		'25': '25 cm',
		'30': '30 cm'
	}

	const windowOptions = {
		null: t('web.forms.choose'),
		WOODEN: t('web.woltadvisor.WOODEN'),
		DOUBLE: t('web.woltadvisor.DOUBLE'),
		TRIPLE: t('web.woltadvisor.TRIPLE')
	}

	type RoofOptions = keyof typeof roofOptions
	type FacadeOptions = keyof typeof facadeOptions
	type WindowOptions = keyof typeof windowOptions

	$: selectedRoof = {
		label: roofOptions[$formData.roof],
		value: $formData.roof
	}

	$: selectedFacade = {
		label: facadeOptions[$formData.facade],
		value: $formData.facade
	}

	$: selectedWindow = {
		label: windowOptions[$formData.window],
		value: $formData.window
	}

	let calculatedHeatLoss: number | null = null
	let buttonState: 'DEFAULT' | 'LOADING' | 'DISABLED' = 'DISABLED'
	let mainError = ''

	export const schema = z.object({
		roof: z
			.enum(Object.keys(roofOptions) as [RoofOptions, ...RoofOptions[]])
			.nullable()
			.default(null)
			.refine((value) => value !== null, {
				message: t('web.forms.field.required')
			}),
		facade: z
			.enum(Object.keys(facadeOptions) as [FacadeOptions, ...FacadeOptions[]])
			.nullable()
			.default(null)
			.refine((value) => value !== null, {
				message: t('web.forms.field.required')
			}),
		window: z
			.enum(Object.keys(windowOptions) as [WindowOptions, ...WindowOptions[]])
			.nullable()
			.default(null)
			.refine((value) => value !== null, {
				message: t('web.forms.field.required')
			}),
		floorSquare: z
			.string()
			.default('')
			.refine((value) => value.length >= 2, {
				message: t('web.forms.field.required')
			}),
		zip: z
			.string()
			.default('')
			.refine(
				(value) =>
					(value.length === 6 && (value.includes('-') || value.includes('–'))) ||
					value.length === 5,
				{
					message: t('web.forms.leaveContact.zip.validation')
				}
			)
	})

	const defaultValues = defaults(zod(schema))

	if ($houseHeatLossModalState.formData?.roof)
		defaultValues.data.roof = $houseHeatLossModalState.formData.roof
	if ($houseHeatLossModalState.formData?.facade)
		defaultValues.data.facade = $houseHeatLossModalState.formData.facade
	if ($houseHeatLossModalState.formData?.window)
		defaultValues.data.window = $houseHeatLossModalState.formData.window
	if ($houseHeatLossModalState.formData?.floorSquare)
		defaultValues.data.floorSquare = $houseHeatLossModalState.formData.floorSquare
	if ($marketplaceDataState.floorSquare)
		defaultValues.data.floorSquare = $marketplaceDataState.floorSquare
	if ($houseHeatLossModalState.formData?.zip)
		defaultValues.data.zip = $houseHeatLossModalState.formData.zip
	if ($marketplaceDataState.zip) defaultValues.data.zip = $marketplaceDataState.zip

	let timer: NodeJS.Timeout | undefined

	function debounce<T extends unknown[]>(func: (...args: T) => void, timeout = 500) {
		return (...args: T): void => {
			if (timer) {
				clearTimeout(timer)
			}
			timer = setTimeout(() => {
				func(...args)
			}, timeout)
		}
	}

	const form = superForm(defaultValues, {
		SPA: true,
		validators: zodClient(schema),
		onUpdate: async ({ form }) => {
			if (form.valid && calculatedHeatLoss !== null) {
				houseHeatLossModalState.update((state) => ({
					...state,
					value: calculatedHeatLoss,
					valueType: 'CALCULATED',
					formData: $formData
				}))
				$heatLossModalOpenState = false

				setCookie('HEAT_LOSS_DATA', JSON.stringify($houseHeatLossModalState), 30)

				const cityNameReal = await getCityNameFromZip($formData.zip)

				marketplaceDataState.update((state) => ({
					...state,
					zip: $formData.zip,
					cityNameReal: cityNameReal,
					floorSquare: $formData.floorSquare
				}))
			}
		},
		onChange: async (): Promise<void> => {
			debounce(async () => {
				formData.update((state) => ({
					...state,
					zip: state.zip.replace(/[^\d\-–]/g, ''),
					floorSquare: state.floorSquare.replace(/\D/g, '')
				}))

				const validation = await validateForm()

				if (validation.valid) {
					let timer = setTimeout(() => {
						buttonState = 'LOADING'
					}, 500)
					const heatLoss = await $houseHeatLossModalState.calculate({
						zip: $formData.zip,
						floorSquare: $formData.floorSquare,
						window: $formData.window,
						roof: $formData.roof,
						facade: $formData.facade
					})
					clearTimeout(timer)

					if (heatLoss) {
						mainError = ''
						calculatedHeatLoss = heatLoss
						buttonState = 'DEFAULT'
					} else {
						mainError = t('web.modals.thermalLoss.error') as string
						buttonState = 'DISABLED'
					}
				} else {
					buttonState = 'DISABLED'
				}
			})()
		}
	})

	const { form: formData, enhance, validateForm, errors } = form

	onMount(() => {
		// kinda hacky just to force form to recalculate heat loss value if init values are all filled
		if ($formData.zip) {
			const tempZip = $formData.zip
			$formData.zip = $formData.zip + ' '
			$formData.zip = tempZip
		}
	})
</script>

<form use:enhance class="flex w-full flex-col p-4" method="POST">
	<div class="mt-4 grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-3">
		<div class="col-span-1 flex w-full flex-col gap-2">
			<Field {form} name="roof">
				<Control let:attrs>
					<Label class="text-sm text-w-blue-950">{t('web.woltadvisor.roof')}</Label>
					<Select.Root
						preventScroll={false}
						selected={selectedRoof}
						onSelectedChange={(selected) => {
							selected && ($formData.roof = selected.value)
						}}
					>
						<Select.Input name={attrs.name} />
						<Select.Trigger
							{...attrs}
							class="flex w-full items-center justify-between rounded-md border border-neutral-300 p-3 text-base sm:text-sm
						{selectedRoof.value === null && 'text-w-blue-950/40'} {$errors.roof && 'border-w-red-500'}"
							aria-label={t('web.forms.choose')}
						>
							{selectedRoof.label}
							<div class="flex min-w-6 justify-end">
								<ChevronDown color="#061c3e" size={20} />
							</div>
						</Select.Trigger>
						<Select.Content
							transition={flyAndScale}
							class="hide-scrollbar z-[10000] max-h-56 overflow-scroll rounded-lg border border-neutral-300 bg-white p-1.5 shadow-xl"
							sideOffset={8}
							sameWidth={true}
							side="bottom"
							avoidCollisions={false}
						>
							{#each Object.entries(roofOptions) as [value, label]}
								<Select.Item
									class="flex w-full cursor-pointer select-none items-center rounded-md px-2 py-6 text-base outline-none transition-all duration-75 data-[highlighted]:bg-neutral-100 sm:text-sm md:py-3 {value ===
										null && 'hidden'}"
									{value}
									{label}
								>
									{label}
									<Select.ItemIndicator asChild={false} class="ml-auto">
										{#if selectedRoof.value !== null}<Check
												color="#061c3e"
												class="h-4"
											/>{/if}</Select.ItemIndicator
									>
								</Select.Item>
							{/each}
						</Select.Content>
					</Select.Root>
					<FieldErrors class="text-xs text-w-red-500" />
				</Control>
			</Field>
		</div>
		<div class="col-span-1 flex w-full flex-col gap-2">
			<Field {form} name="facade">
				<Control let:attrs>
					<Label class="text-sm text-w-blue-950">{t('web.woltadvisor.facade')}</Label>
					<Select.Root
						preventScroll={false}
						selected={selectedFacade}
						onSelectedChange={(selected) => {
							selected && ($formData.facade = selected.value)
						}}
					>
						<Select.Input name={attrs.name} />
						<Select.Trigger
							{...attrs}
							class="flex w-full items-center justify-between rounded-md border border-neutral-300 p-3 text-base sm:text-sm
						{selectedFacade.value === null && 'text-w-blue-950/40'} {$errors.facade && 'border-w-red-500'}"
							aria-label={t('web.forms.choose')}
						>
							{selectedFacade.label}
							<div class="flex min-w-6 justify-end">
								<ChevronDown color="#061c3e" size={20} />
							</div>
						</Select.Trigger>
						<Select.Content
							transition={flyAndScale}
							class="hide-scrollbar z-[10000] max-h-56 overflow-scroll rounded-lg border border-neutral-300 bg-white p-1.5 shadow-xl"
							sideOffset={8}
							sameWidth={true}
							side="bottom"
							avoidCollisions={false}
						>
							{#each Object.entries(facadeOptions) as [value, label]}
								<Select.Item
									class="flex w-full cursor-pointer select-none items-center rounded-md px-2 py-6 text-base outline-none transition-all duration-75 data-[highlighted]:bg-neutral-100 sm:text-sm md:py-3 {value ===
										null && 'hidden'}"
									{value}
									{label}
								>
									{label}
									<Select.ItemIndicator asChild={false} class="ml-auto">
										{#if selectedFacade.value !== null}<Check
												color="#061c3e"
												class="h-4"
											/>{/if}</Select.ItemIndicator
									>
								</Select.Item>
							{/each}
						</Select.Content>
					</Select.Root>
					<FieldErrors class="text-xs text-w-red-500" />
				</Control>
			</Field>
		</div>
		<div class="col-span-1 flex w-full flex-col gap-2">
			<Field {form} name="window">
				<Control let:attrs>
					<Label class="text-sm text-w-blue-950">{t('web.woltadvisor.windows')}</Label>
					<Select.Root
						preventScroll={false}
						selected={selectedWindow}
						onSelectedChange={(selected) => {
							selected && ($formData.window = selected.value)
						}}
					>
						<Select.Input name={attrs.name} />
						<Select.Trigger
							{...attrs}
							class="flex w-full items-center justify-between rounded-md border border-neutral-300 p-3 text-base sm:text-sm
						{selectedWindow.value === null && 'text-w-blue-950/40'} {$errors.window && 'border-w-red-500'}"
							aria-label={t('web.forms.choose')}
						>
							{selectedWindow.label}
							<div class="flex min-w-6 justify-end">
								<ChevronDown color="#061c3e" size={20} />
							</div>
						</Select.Trigger>
						<Select.Content
							transition={flyAndScale}
							class="hide-scrollbar z-[10000] max-h-56 overflow-scroll rounded-lg border border-neutral-300 bg-white p-1.5 shadow-xl"
							sideOffset={8}
							sameWidth={true}
							side="bottom"
							avoidCollisions={false}
						>
							{#each Object.entries(windowOptions) as [value, label]}
								<Select.Item
									class="flex w-full cursor-pointer select-none items-center rounded-md px-2 py-6 text-base outline-none transition-all duration-75 data-[highlighted]:bg-neutral-100 sm:text-sm md:py-3 {value ===
										null && 'hidden'}"
									{value}
									{label}
								>
									{label}
									<Select.ItemIndicator asChild={false} class="ml-auto">
										{#if selectedWindow.value !== null}<Check
												color="#061c3e"
												class="h-4"
											/>{/if}</Select.ItemIndicator
									>
								</Select.Item>
							{/each}
						</Select.Content>
					</Select.Root>
					<FieldErrors class="text-xs text-w-red-500" />
				</Control>
			</Field>
		</div>
	</div>
	<div class="mt-2 grid grid-cols-1 gap-x-4 gap-y-2 sm:mt-8 sm:grid-cols-2">
		<div class="col-span-1 flex w-full flex-col gap-2">
			<Field {form} name="floorSquare">
				<Control let:attrs>
					<Label class="text-sm text-w-blue-950">{t('web.woltadvisor.floorSquare')}</Label>
					<div
						class="relative flex w-full items-center justify-between rounded-md border border-neutral-300 text-sm {$errors.floorSquare &&
							'border-w-red-500'}"
					>
						<input
							{...attrs}
							type="text"
							bind:value={$formData.floorSquare}
							placeholder="120"
							class="flex w-full items-center justify-between rounded-md border-none p-3 text-base placeholder:text-w-blue-950/40 sm:text-sm"
						/>
						<span class="absolute right-0 mx-2 text-black/60">m2</span>
					</div>
					<FieldErrors class="text-xs text-w-red-500" />
				</Control>
			</Field>
		</div>
		<div class="col-span-1 flex w-full flex-col gap-2">
			<Field {form} name="zip">
				<Control let:attrs>
					<Label class="text-sm text-w-blue-950">{t('web.marketplace.filter.zipcode')}</Label>
					<input
						{...attrs}
						type="text"
						bind:value={$formData.zip}
						placeholder={t('web.forms.zipcode.placeholder')}
						class="flex w-full items-center justify-between rounded-md border border-neutral-300 p-3 text-base placeholder:text-w-blue-950/40 sm:text-sm {$errors.zip &&
							'border-w-red-500'}"
					/>
					<FieldErrors class="text-xs text-w-red-500" />
				</Control>
			</Field>
		</div>
	</div>

	<div class="mt-auto">
		<div
			class="mb-6 mt-4 flex w-full flex-col items-center gap-4 rounded-lg bg-neutral-200/80 px-4 py-6 sm:flex-row"
		>
			<Info color="rgba(0, 0, 0, 0.6)" size={18} />
			<p class="unstyled flex-1 text-xs text-black/60">
				{@html t('web.modals.thermalLoss.box')}
			</p>
		</div>
		<button
			class="btn-new btn-new-blue w-full transition-opacity {buttonState === 'LOADING' &&
				'pointer-events-none opacity-60'} {buttonState === 'DISABLED' && 'opacity-60'}"
			type="submit"
		>
			<LoaderCircle
				size={16}
				color="white"
				class="animate-spin transition-opacity {buttonState === 'LOADING'
					? 'opacity-100'
					: 'opacity-0'}"
			/>
			<span class="pr-5">
				{#if buttonState === 'DISABLED' || calculatedHeatLoss === null}
					{t('web.marketplace.filter.fillthedetails')}
				{:else}
					{t('web.modals.thermalLoss.button', {
						value: calculatedHeatLoss.toString() || '0'
					})}
				{/if}</span
			></button
		>
		{#if mainError.length > 0}
			<p class="unstyled mt-2 text-center text-xs text-w-red-500">{mainError}</p>
		{/if}
	</div>
</form>
