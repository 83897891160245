import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

type ExitModal = {
	open: boolean
}

const defaults: ExitModal = {
	open: false
}

const EXIT_MODAL_CTX = 'EXIT_MODAL_CTX'

export function setExitModalState(data?: ExitModal) {
	const state = writable<ExitModal>({
		...defaults,
		...(data && { ...data })
	})
	setContext(EXIT_MODAL_CTX, state)
	return state
}

export function getExitModalState() {
	return getContext<Writable<ExitModal>>(EXIT_MODAL_CTX)
}
