<script lang="ts">
	import { BROWSER } from 'esm-env'
	import { getExpertContactModalState } from '$lib/stores'
	import { afterNavigate, goto } from '$app/navigation'
	import FormStep from './steps/FormStep.svelte'
	import ThankYouStep from './steps/ThankYouStep.svelte'
	import { onMount } from 'svelte'
	import { page } from '$app/stores'

	const expertContactModalState = getExpertContactModalState()

	afterNavigate((event) => {
		if (event.from?.url.pathname !== event.to?.url.pathname) {
			expertContactModalState.update((state) => ({
				...state,
				step: 'FORM',
				defaultFormType: undefined
			}))
		}
	})

	onMount(() => {
		$page.url.searchParams.get('openExpertContact') &&
			expertContactModalState.update((state) => ({
				...state,
				open: true
			}))
	})

	$: expertContactModalState.subscribe((state) => {
		if (BROWSER) {
			if (state.open === true) {
				const scrollX = document.querySelector('html')?.scrollTop || 0
				window.onscroll = () => window.scrollTo(0, scrollX)
			} else {
				window.onscroll = (event) => event
			}
		}
	})
</script>

{#if $expertContactModalState?.open}
	<div
		class="hide-scrollbar animate-opacity fixed left-0 top-0 z-[10000] flex h-full w-full items-start justify-center overflow-y-scroll bg-neutral-300/20 backdrop-blur-sm"
	>
		<div
			class="absolute h-full w-full"
			role="none"
			on:click={() => {
				if (
					$expertContactModalState.step === 'THANK_YOU' &&
					$page.url.searchParams.get('openExpertContact')
				) {
					const url = new URL(window.location.href)
					url.searchParams.delete('openExpertContact')
					goto(url, { replaceState: true })
				}
				expertContactModalState.update((state) => ({
					...state,
					open: false
				}))
			}}
		/>
		<div class="animate-in relative grid w-fit grid-cols-1 grid-rows-1 justify-center">
			<div
				class="col-start-1 row-start-1 transition-opacity {$expertContactModalState?.step === 'FORM'
					? 'z-10 opacity-100'
					: 'pointer-events-none -z-10 h-0 overflow-hidden opacity-0'}"
			>
				<FormStep />
			</div>
			<div
				class="col-start-1 row-start-1 transition-opacity {$expertContactModalState?.step ===
				'THANK_YOU'
					? 'z-10 opacity-100'
					: 'pointer-events-none -z-10 opacity-0'}"
			>
				<ThankYouStep />
			</div>
		</div>
	</div>
{/if}

<style lang="postcss">
	.animate-in {
		animation: slide-in 0.25s ease-out both;
	}

	.animate-opacity {
		animation: opacity-in 0.25s ease-out both;
	}

	@keyframes slide-in {
		0% {
			transform: translateY(-20%) scale(0.5);
			opacity: 0;
		}
		100% {
			transform: translateY(0) scale(1);
			opacity: 1;
		}
	}

	@keyframes opacity-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
