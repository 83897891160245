<script lang="ts">
	import { t } from '$lib/translations'
	import { X } from 'lucide-svelte'
	import { hvacBrands } from '$lib/consts'
	import { getAdvancedFilterModalState, getMarketplaceDataState } from '$lib/stores'
	import { BROWSER } from 'esm-env'
	import { page } from '$app/stores'
	import { Tabs, ToggleGroup } from 'bits-ui'
	import { setCookie } from '$lib/utils'

	const advancedFilterModalState = getAdvancedFilterModalState()
	const marketplaceDataState = getMarketplaceDataState()

	$: brands = Object.values(hvacBrands).filter((brand) =>
		brand.countries.includes($page.data.country)
	)

	const brandImages: Record<string, string> = {
		'alpha-innotec': '/images/brands/marketplace/alpha-innotec.jpg',
		buderus: '/images/brands/marketplace/buderus.svg',
		bosch: '/images/brands/marketplace/bosch.svg',
		daikin: '/images/brands/marketplace/daikin.svg',
		'de-dietrich': '/images/brands/marketplace/de-dietrich.svg',
		grundig: '/images/brands/marketplace/grundig.svg',
		hsd: '/images/brands/marketplace/hsd.png',
		kronoterm: '/images/brands/marketplace/kronoterm.svg',
		lg: '/images/brands/marketplace/lg.svg',
		maxa: '/images/brands/marketplace/maxa.svg',
		nibe: '/images/brands/marketplace/nibe.svg',
		regulus: '/images/brands/marketplace/regulus.svg',
		samsung: '/images/brands/marketplace/samsung.svg',
		stiebel: '/images/brands/marketplace/stiebel.svg',
		templari: '/images/brands/marketplace/templari.svg',
		vaillant: '/images/brands/marketplace/vaillant.svg',
		viessmann: '/images/brands/marketplace/viessmann.svg',
		wltr: '/images/brands/marketplace/wltr.svg',
		panasonic: '/images/brands/marketplace/panasonic.svg',
		york: '/images/brands/marketplace/york.svg'
	}

	$: advancedFilterModalState.subscribe((state) => {
		if (BROWSER) {
			if (state.open === true) {
				const scrollX = document.querySelector('html')?.scrollTop || 0
				window.onscroll = () => window.scrollTo(0, scrollX)
			} else {
				window.onscroll = (event) => event
			}
		}
	})

	let noiseLevel: string

	$: $advancedFilterModalState?.open, (noiseLevel = $marketplaceDataState.noiseLevel || '')

	function setGlobalData(data: Record<string, string | number | boolean | null | undefined>) {
		marketplaceDataState.update((state) => ({
			...state,
			...data
		}))
		setCookie('MARKETPLACE_FILTER_DATA', JSON.stringify(data), 30)
	}

	let activeTab = 0
	$: $advancedFilterModalState?.open, (activeTab = $advancedFilterModalState.showTab)
</script>

{#if $advancedFilterModalState?.open}
	<div
		class="hide-scrollbar animate-opacity fixed left-0 top-0 z-[10000] flex h-full w-full items-start justify-center overflow-y-scroll bg-neutral-300/20 backdrop-blur-sm"
	>
		<div
			class="absolute h-full w-full"
			role="none"
			on:click={() => {
				advancedFilterModalState.update((state) => ({
					...state,
					open: false,
					showTab: 0
				}))
			}}
		/>

		<div
			class="animate-in relative mt-2 flex min-h-screen w-full max-w-[780px] flex-col overflow-hidden bg-white pt-8 shadow-lg sm:mx-2 sm:my-8 sm:mt-20 sm:min-h-[500px] sm:rounded-3xl"
		>
			<h2 class="unstyled px-4 text-left text-2xl font-medium sm:px-8">
				{t('web.marketplace.advancedFilter')}
			</h2>

			<Tabs.Root value={activeTab.toString()}>
				<Tabs.List
					class="mx-3 my-6 grid grid-cols-2 gap-1 rounded-lg p-1 text-w-blue-950 sm:mx-8 sm:bg-neutral-200/70"
				>
					<Tabs.Trigger
						value="0"
						class="col-span-1 w-full rounded-md border border-neutral-200 bg-transparent bg-white py-2 active:bg-w-red-300 data-[state=active]:border-w-blue-950 data-[state=active]:bg-w-blue-950 data-[state=active]:text-white sm:border-none sm:bg-transparent sm:data-[state=active]:bg-white sm:data-[state=active]:text-w-blue-950"
						>{t('web.forms.brand')}</Tabs.Trigger
					>
					<Tabs.Trigger
						value="1"
						class="col-span-1 w-full rounded-md border border-neutral-200 bg-transparent bg-white py-2 active:bg-w-red-300 data-[state=active]:border-w-blue-950 data-[state=active]:bg-w-blue-950 data-[state=active]:text-white sm:border-none sm:bg-transparent sm:data-[state=active]:bg-white sm:data-[state=active]:text-w-blue-950"
						>{t('web.forms.params')}</Tabs.Trigger
					>
				</Tabs.List>
				<Tabs.Content value="0">
					<h3 class="unstyled mt-4 px-4 text-left text-lg font-medium sm:px-8">
						{t('web.marketplace.filter.brands')}
					</h3>
					<div
						class="mt-4 grid auto-rows-fr grid-cols-2 gap-3 px-4 xs:grid-cols-3 sm:grid-cols-4 sm:px-8"
					>
						{#each brands as brand}
							<button
								on:click={() => {
									marketplaceDataState.update((state) => {
										const index = state.supplierTitles?.indexOf(brand.name) ?? -1
										let newSupplierTitles
										if (index !== -1) {
											newSupplierTitles = [...state.supplierTitles]
											newSupplierTitles.splice(index, 1)
										} else {
											newSupplierTitles = [...(state.supplierTitles ?? []), brand.name]
										}
										return {
											...state,
											supplierTitles: newSupplierTitles.length > 0 ? newSupplierTitles : null
										}
									})
									setGlobalData($marketplaceDataState)
								}}
								class="inline-block rounded-lg border-2 p-4 align-top transition-colors {(
									$marketplaceDataState.supplierTitles ?? []
								).includes(brand.name)
									? 'border-w-red-500 hover:border-w-red-500'
									: 'border-neutral-200 hover:border-neutral-500'}"
							>
								<span class="flex h-auto max-h-12">
									<img
										src={brandImages[brand.slug]}
										alt="{brand.name} logo"
										class="object-contain"
									/>
								</span>
							</button>
						{/each}
					</div>

					{#if $marketplaceDataState.supplierTitles}
						<div class="mx-4 mt-4 rounded-xl bg-neutral-100 sm:mx-8 sm:mt-8">
							{#each brands as brand}
								{#if $marketplaceDataState.supplierTitles.includes(brand.name)}
									<div class="px-5 py-4">
										<h4 class="unstyled font-semibold">{brand.name}</h4>
										<p class="unstyled mt-2 text-sm opacity-70">
											{t(`web.marketplace.brand.${brand.slug}`)}
										</p>
									</div>
								{/if}
							{/each}
						</div>
					{/if}
				</Tabs.Content>
				<Tabs.Content value="1">
					<h3 class="unstyled mt-4 px-4 text-left text-lg font-medium sm:px-8">
						{t('web.marketplace.filter.noiseLevel')}
					</h3>
					<div class="mt-3 px-4 sm:px-8">
						<ToggleGroup.Root
							class="flex w-fit flex-wrap gap-2 rounded-lg"
							bind:value={noiseLevel}
							onValueChange={(value) => {
								marketplaceDataState.update((state) => ({
									...state,
									noiseLevel: value === '' ? null : value
								}))
								setGlobalData($marketplaceDataState)
							}}
						>
							<ToggleGroup.Item
								class="max-w-fit flex-1 text-nowrap rounded-lg bg-neutral-100 px-4 py-2 text-sm font-medium transition-colors hover:bg-neutral-200 data-[state=on]:bg-w-red-500 data-[state=on]:text-white"
								value="">{t('web.marketplace.filter.noLimit')}</ToggleGroup.Item
							><ToggleGroup.Item
								class="max-w-fit flex-1 text-nowrap rounded-lg bg-neutral-100 px-4 py-2 text-sm font-medium transition-colors hover:bg-neutral-200 data-[state=on]:bg-w-red-500 data-[state=on]:text-white"
								value="60">{t('web.marketplace.filter.upto')} 60 db</ToggleGroup.Item
							>

							<ToggleGroup.Item
								class="max-w-fit flex-1 text-nowrap rounded-lg bg-neutral-100 px-4 py-2 text-sm font-medium transition-colors hover:bg-neutral-200 data-[state=on]:bg-w-red-500 data-[state=on]:text-white"
								value="50">{t('web.marketplace.filter.upto')} 50 db</ToggleGroup.Item
							>
							<ToggleGroup.Item
								class="max-w-fit flex-1 text-nowrap rounded-lg bg-neutral-100 px-4 py-2 text-sm font-medium transition-colors hover:bg-neutral-200 data-[state=on]:bg-w-red-500 data-[state=on]:text-white"
								value="40">{t('web.marketplace.filter.upto')} 40 db</ToggleGroup.Item
							>
						</ToggleGroup.Root>
					</div>
				</Tabs.Content>
			</Tabs.Root>

			<div class="mt-auto p-4 sm:p-8">
				<button
					class="btn-new btn-new-blue w-full"
					on:click={() => {
						advancedFilterModalState.update((state) => ({
							...state,
							open: false,
							showTab: 0
						}))
					}}>{t('web.marketplace.filter.hvac.showFiltered')}</button
				>
			</div>
			<button
				class="absolute right-0 top-0 rounded-bl-3xl px-4 py-3.5"
				on:click={() => {
					advancedFilterModalState.update((state) => ({
						...state,
						open: false,
						showTab: 0
					}))
				}}
				><X color="#061c3e" class="hidden sm:block" size={16} />
				<X color="#061c3e" class="sm:hidden" size={24} /></button
			>
		</div>
	</div>
{/if}

<style>
	.animate-in {
		animation: slide-in 0.25s ease-out both;
	}

	.animate-opacity {
		animation: opacity-in 0.25s ease-out both;
	}

	@keyframes slide-in {
		0% {
			transform: translateY(-20%) scale(0.5);
			opacity: 0;
		}
		100% {
			transform: translateY(0) scale(1);
			opacity: 1;
		}
	}

	@keyframes opacity-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
