<script lang="ts">
	import { getCookie } from '$lib/utils'
	import { page } from '$app/stores'
	import { BROWSER } from 'esm-env'
	import {
		setPreLeadModalState,
		setGrantsModalState,
		setNewsletterPopupState,
		setHeatLossModalState,
		setWebSessionState,
		setExitModalState,
		setMarketplaceDataState,
		setAdvancedFilterModalState,
		setMarketplaceLeadModalState,
		setExpertContactModalState,
		setMarketplaceWatchdogModalState,
		setHeatLossModalOpenState,
		setMarketplaceContactInputState
	} from './index'

	const marketplaceDataState = setMarketplaceDataState()
	const newsletterPopupState = setNewsletterPopupState()
	const marketplaceContactInput = setMarketplaceContactInputState()
	setHeatLossModalState()
	setHeatLossModalOpenState(false)
	setWebSessionState()
	setExitModalState()
	setPreLeadModalState()
	setGrantsModalState()
	setAdvancedFilterModalState()
	setMarketplaceLeadModalState()
	setExpertContactModalState()
	setMarketplaceWatchdogModalState()

	newsletterPopupState.update((state) => ({ ...state, open: false }))

	const urlDataWhiteList = [
		'zip',
		'peopleCount',
		'houseHeatType',
		'waterTempFortyFive',
		'floorSquare',
		'quickInstall',
		'waterHeatingWithHouseHeatSource',
		'houseHeatLoss',
		'filterResultWaterSystem',
		'noiseLevel',
		'supplierTitles'
	]

	if (BROWSER) {
		restoreMarketplaceData()
	}

	function restoreMarketplaceData() {
		const cookieData = getCookie('MARKETPLACE_FILTER_DATA') || '{}'
		const urlData: Record<string, any> = {}

		for (const [key, value] of $page.url.searchParams) {
			if (urlDataWhiteList.includes(key)) {
				urlData[key] = value
			}
		}

		marketplaceDataState.set({ ...JSON.parse(cookieData), ...urlData })
		const userCookieData = getCookie('MARKETPLACE_CONTACT_INPUT') || '{}'
		if (userCookieData) marketplaceContactInput.set({ ...JSON.parse(userCookieData) })
	}
</script>

<slot />
