import { initialize } from '@woltair/feature-flags-fe/client'
import { initTranslations } from '@woltair/translations-package-fe'
import { BROWSER } from 'esm-env'
import { building } from '$app/environment'
import { COUNTRY_CODE_A3 } from '$lib/consts'
import { LANGUAGES } from '$lib/consts/languages.js'
import { FEATURE_CONFIG } from '$lib/feature-config'
import { getCookie, setCookie } from '$lib/utils'
import type { LayoutLoad } from './$types'
import { PUBLIC_TRANSLATIONS_SERVER, PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

export const load: LayoutLoad = async ({ data, fetch }) => {
	if (BROWSER) {
		logger.log(
			`Initializing translations "${LANGUAGES[PUBLIC_WOLTAIR_WEB_CODE as keyof typeof LANGUAGES]}".`
		)
		const t = await initTranslations({
			language: data.organization.lang.toLocaleLowerCase(),
			serverUrl: PUBLIC_TRANSLATIONS_SERVER,
			debug: false,
			subset: 'web/web',
			fetchFunction: fetch,
			loadAllLanguages: false
		})

		const LDcontext = {
			organization: {
				key: PUBLIC_WOLTAIR_WEB_CODE,
				country: COUNTRY_CODE_A3
			}
		}
		//if (PUBLIC_ENVIRONMENT !== 'local')
		await initialize(LDcontext, import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID)

		if (document.title.startsWith('web.')) {
			// we log this twice because we want to reduce error spaming in Sentry, and we want to have enough informations
			logger.log(
				'web.translations',
				`Missing translation in page ${window.location.host}${window.location.pathname} (${document.title})`
			)
			logger.error('web.translations', `Missing translation of meta title`)
		}
		const cachedUser = localStorage.getItem('cachedUser')
		const czaToken = getCookie('CZA_TOKEN')
		const czaTokenCached = getCookie('CACHED_USER_CZA_TOKEN')

		if (!building && FEATURE_CONFIG.userZone && czaToken) {
			let user

			if (cachedUser && czaTokenCached === czaToken) {
				user = JSON.parse(cachedUser)
			} else {
				user = await fetch('/api/user').then((r) => r.json())
				if (BROWSER) {
					localStorage.setItem('cachedUser', JSON.stringify(user))
					setCookie('CACHED_USER_CZA_TOKEN', czaToken, 30)
				}
			}

			data = {
				...data,
				user,
				t
			}
		} else if (cachedUser) {
			localStorage.removeItem('cachedUser')
		}
	}

	return data
}
