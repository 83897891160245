<script lang="ts">
	//import { onMount } from 'svelte'
	import { page, updated } from '$app/stores'

	//export let drawer = false
	//export let topOffset = 80
	//export let headless = false

	//let header: HTMLElement
	//let lastScrollTop = 0

	// onMount(() => {
	// 	const handleScroll = () => {
	// 		const scrollTop = window.pageYOffset || document.documentElement.scrollTop

	// 		if (
	// 			scrollTop > lastScrollTop &&
	// 			scrollTop >= topOffset &&
	// 			!drawer &&
	// 			window.innerWidth < 768
	// 		) {
	// 			header.style.top = `-${topOffset}px`
	// 		} else {
	// 			header.style.top = '0'
	// 		}

	// 		lastScrollTop = scrollTop
	// 	}

	// 	window.addEventListener('scroll', handleScroll)

	// 	// Cleanup function
	// 	return () => {
	// 		window.removeEventListener('scroll', handleScroll)
	// 	}
	// })

	// let supheaders: any
	// let intersecting: any
</script>

<slot name="supheader" />
<slot name="header" />

<div class="sticky top-[78px] z-[999] h-0 overflow-y-visible">
	<!-- <div class="h-40 w-40 bg-blue-400" /> -->
	<slot name="drawer" />
</div>

<div class="relative" data-sveltekit-reload={$updated ? '' : 'off'}>
	<div
		class="absolute -z-50 h-full w-full"
		class:bg-neutral-100={$page.data.layout?.background === 'gray'}
	/>
	<!-- <header
		class="header sticky top-0 z-50 h-auto max-h-20 w-full bg-white"
		bind:this={header}
		bind:offsetHeight={topOffset}
	>
	</header> -->

	<main class="min-h-screen">
		<slot />
	</main>

	<footer class="bg-white">
		<slot name="footer" />
	</footer>
</div>

<style lang="scss">
	// .header {
	// 	transition-property: top;
	// 	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	// 	transition-duration: 700ms;
	// }
</style>
