<script lang="ts">
	import { BROWSER } from 'esm-env'
	import { onMount } from 'svelte'
	import { afterNavigate } from '$app/navigation'
	import { page } from '$app/stores'
	import { sendGTM } from '$lib/analytics'
	import { CONTACT_VARS } from '$lib/consts'
	import { t } from '$lib/translations'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import { getExpertContactModalState } from '$lib/stores'

	const expertContactModalState = getExpertContactModalState()

	export let drawer: boolean
	export let toggleDrawer: () => void

	export let data: {
		data: {
			attributes: {
				showLogin: boolean
				showPhone: boolean
				navLinks: {
					text: string
					path: string
					description: string
					subLinks: {
						text: string
						path: string
						description: string
					}[]
				}[]
			}
		}
	}

	const navLinks = data.data.attributes.navLinks
	const showLogin = data.data.attributes.showLogin
	const showPhone = data.data.attributes.showPhone
	const showExpertCallButton = data.data.attributes.showExpertCallButton

	$: y = 0

	$: userLoggedIn = BROWSER && $page.data?.user?.name

	let lastElement: HTMLElement

	let hideNavContent = false

	let navItems: HTMLElement[] = []

	onMount(() => {
		lastElement = navItems[navItems.length - 1]
		if (lastElement.offsetTop < 0) hideNavContent = true
	})

	afterNavigate(() => {
		// closes nav menu when navigating to another page
		navItems.forEach((item) => {
			item.classList.remove('nav-item--hover-behaviour')
			setTimeout(() => {
				item.classList.add('nav-item--hover-behaviour')
			}, 100)
		})
	})
</script>

<svelte:window
	on:resize={() => {
		if (lastElement.offsetTop < 0) hideNavContent = true
		else hideNavContent = false
	}}
	bind:scrollY={y}
/>

<!-- {y < 32 && $page.data.layout?.navigation?.paddingTop
	? 'pt-[0rem] md:pt-8'
	: 'pt-[0rem] md:pt-[0rem]'}" -->

<!-- on:click={() => mouseIn === false && drawer && toggleDrawer()} -->

{#if $page.data.layout?.navigation?.paddingTop}
	<div
		class="relative z-[1000] hidden h-8 w-full transition-colors duration-150 ease-linear md:block {y <
		32
			? 'bg-transparent'
			: ' bg-white'}"
	/>
{/if}

<nav
	class="transit-custom sticky top-0 z-[1000] box-border flex w-full items-center overflow-x-clip {y <
		32 &&
	$page.data.layout?.navigation?.paddingTop &&
	drawer !== true
		? ''
		: 'border-b bg-white'}"
>
	<div
		class="page-wrapper relative flex h-full items-center justify-between gap-8 overflow-y-visible"
	>
		<div>
			<a
				href="/"
				on:click={() => {
					if (drawer) toggleDrawer()
				}}
				><img
					src="/images/logos/woltair-red.svg"
					alt="Woltair"
					class="min-w-[158px]"
					width="158"
					height="28"
				/></a
			>
		</div>
		<div
			class="safari-fix pointer-events-none !static h-full w-full opacity-0 md:pointer-events-auto md:opacity-100"
		>
			<div
				class="!static flex h-full max-h-[78px] w-full flex-wrap-reverse items-end justify-center gap-8"
			>
				{#each navLinks as link, i}
					<a
						href={link.path}
						class="nav-item nav-item--hover-behaviour my-7 flex h-full flex-col items-center overflow-visible whitespace-nowrap border-b-2 border-transparent text-sm text-w-blue-950 {hideNavContent
							? 'md:pointer-events-none md:opacity-0'
							: 'md:pointer-events-auto md:opacity-100'} {link.subLinks?.length
							? 'nav-item--show-border'
							: 'hover:text-w-red-500'}"
						bind:this={navItems[i]}
						class:text-w-red-500={$page.url.pathname.split('/')[1] === link.path.split('/')[1]}
						>{link.text}
						{#if link.subLinks?.length}
							<div
								class="cursor-box pointer-events-none absolute bottom-0 left-0 h-7 w-full opacity-0"
							/>
						{/if}
						{#if link.subLinks?.length}
							<div
								class="nav-submenu pointer-events-none absolute left-0 top-[78px] grid w-full grid-cols-3 overflow-hidden bg-white opacity-0 transition-opacity {y <
									32 &&
								$page.data.layout?.navigation?.paddingTop &&
								drawer !== true
									? 'rounded-xl'
									: 'rounded-b-xl'} shadow-lg"
							>
								<a
									href={link.path}
									class="submenu-item flex flex-col items-start gap-3 whitespace-nowrap border-b border-[rgba(0,0,0,0.07)] p-8 transition-colors hover:bg-white hover:text-w-red-500"
								>
									<h4 class="unstyled text-xl font-medium text-w-blue-950">
										{link.text}
									</h4>
									{#if link.description}
										<span class="whitespace-normal text-black opacity-50">
											{link.description}
										</span>
									{/if}
									<span
										class="submenu-item__link-wrapper relative mt-auto flex items-center gap-4 pt-4"
										><img
											src="/images/icons/woltair-ff-logo.svg"
											class="submenu-item__link-logo aspect-square h-[1em] opacity-0 transition-opacity ease-in-out"
											alt="Woltair logo"
										/><span
											class="submenu-item__link-text absolute left-0 font-medium text-w-blue-950 transition-transform ease-in-out"
											>{t('web.nav.learnMore')}</span
										></span
									>
								</a>

								{#each link.subLinks as subLink}
									<a
										href={subLink.path}
										class="submenu-item flex flex-col items-start gap-3 whitespace-nowrap border-b border-[rgba(0,0,0,0.07)] p-8 transition-colors hover:bg-white hover:text-w-red-500"
									>
										<h4 class="unstyled text-xl font-medium text-w-blue-950">
											{subLink.text}
										</h4>
										{#if subLink.description}
											<span class="whitespace-normal text-black opacity-50">
												{subLink.description}
											</span>
										{/if}
										<span
											class="submenu-item__link-wrapper relative mt-auto flex items-center gap-4 pt-4"
											><img
												src="/images/icons/woltair-ff-logo.svg"
												class="submenu-item__link-logo aspect-square h-[1em] opacity-0 transition-opacity ease-in-out"
												alt="Woltair logo"
											/><span
												class="submenu-item__link-text absolute left-0 font-medium text-w-blue-950 transition-transform ease-in-out"
												>{t('web.nav.learnMore')}</span
											></span
										>
									</a>
								{/each}
							</div>
						{/if}</a
					>
				{/each}
			</div>
		</div>
		<div
			class="pointer-events-none flex min-w-fit gap-8 opacity-0
			{hideNavContent ? 'md:pointer-events-none md:opacity-0' : 'md:pointer-events-auto md:opacity-100'}"
		>
			{#if showExpertCallButton}
				<div
					role="button"
					tabindex="0"
					on:click={() => {
						expertContactModalState.update((state) => ({
							...state,
							open: true
						}))
					}}
					on:keydown={(e) => {
						if (e.key == 'Enter') {
							expertContactModalState.update((state) => ({
								...state,
								open: true
							}))
						}
					}}
					class="flex rounded-md bg-primary-500 px-4 py-2 text-sm text-white"
				>
					{t('web.marketplace.button.expertHelp')}
				</div>
			{/if}
			{#if showPhone}
				<a
					href={CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE].phoneRef}
					on:click={() =>
						sendGTM(
							'call_to_action',
							{},
							{
								content: {
									type: 'click_call_me'
								},
								_clear: true
							}
						)}
					class="flex items-center gap-3 whitespace-nowrap text-sm text-w-blue-950 hover:text-w-red-500"
					><img
						src="/images/icons/phone-w-blue.svg"
						alt="phone"
						class="mb-[2px] h-[1em]"
					/>{CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE].phoneNum}</a
				>
			{/if}
			{#if showLogin}
				<a
					class="flex items-center gap-3 whitespace-nowrap text-sm text-w-blue-950 {userLoggedIn
						? 'text-w-red-500'
						: 'hover:text-w-red-500'}"
					href="https://account.{$page.url.hostname.replace('www.', '')}/{userLoggedIn
						? 'orders'
						: 'login'}"
					><img
						src="/images/icons/customer-w-blue.svg"
						alt="login"
						class="mb-[2px] h-[1em]"
					/>{#if userLoggedIn}
						{$page.data?.user?.name?.length > 28
							? t('web.routes.userAccount')
							: $page.data?.user?.name}
					{:else}
						{t('web.routes.login')}{/if}
				</a>
			{/if}
		</div>
		<div
			class="absolute bottom-0 right-1 top-0 my-auto flex h-fit items-center rounded-xl p-4 hover:bg-neutral-200 active:bg-neutral-200 md:pointer-events-none md:opacity-0"
			on:keydown
			on:click={() => toggleDrawer()}
			class:md:opacity-100={hideNavContent}
			class:md:pointer-events-auto={hideNavContent}
			role="button"
			tabindex="0"
		>
			<div class="nav-icon" class:open={drawer}>
				<span />
				<span />
				<span />
				<span />
			</div>
		</div>
	</div>
	<slot />
</nav>

<style lang="scss">
	.transit-custom {
		display: inline-block;
		transition-property: background-color, padding-top, box-shadow;
		transition-duration: 0.15s;
		transition-timing-function: linear;
	}
	.nav-item--hover-behaviour:hover .nav-submenu {
		@apply pointer-events-auto opacity-100;
	}

	.nav-item--hover-behaviour:hover .cursor-box {
		@apply pointer-events-auto opacity-100;
		transform: translate3d(0, 0, 0); //keep here to fix Safari hover bug

		@screen md {
			@apply pointer-events-auto opacity-100;
		}
	}

	.nav-item::after {
		@apply pointer-events-none block h-0.5 w-full translate-y-[30px] bg-w-red-500 opacity-0 transition-opacity content-[""];
	}

	.nav-item--show-border:hover::after {
		@apply opacity-100;
	}

	.submenu-item:not(:nth-of-type(3n)) {
		@apply border-r;
	}

	.submenu-item:nth-of-type(-n + 3) {
		@apply border-t;
	}

	.submenu-item:hover .submenu-item__link-logo {
		@apply opacity-100;
	}

	.submenu-item:hover .submenu-item__link-text {
		@apply translate-x-8 text-w-red-500;
	}

	.nav-icon {
		width: 25px;
		height: 16px;
		position: relative;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
	}

	.nav-icon span:not(.nav-icon-text) {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: theme('colors.w-blue.950');
		border-radius: 5px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;
	}

	/* .nav-button:hover span:not(.nav-icon-text) {
		background: theme('colors.primary-600');
	} */

	.nav-icon span:nth-child(1) {
		top: 0px;
	}

	.nav-icon span:nth-child(2),
	.nav-icon span:nth-child(3) {
		top: 7px;
	}

	.nav-icon span:nth-child(4) {
		top: 14px;
	}

	.nav-icon.open span:nth-child(1) {
		top: 8px;
		width: 0%;
		left: 50%;
	}

	.nav-icon.open span:nth-child(2) {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.nav-icon.open span:nth-child(3) {
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.nav-icon.open span:nth-child(4) {
		top: 8px;
		width: 0%;
		left: 50%;
	}
</style>
