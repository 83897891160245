<script lang="ts">
	import { X } from 'lucide-svelte'
	import { MailCheck } from 'lucide-svelte'
	import { page } from '$app/stores'
	import { CONTACT_VARS } from '$lib/consts'
	import { getExitModalState } from '$lib/stores'
	import { t } from '$lib/translations'
	import { setCookie } from '$lib/utils'
	import ExitForm from './ExitForm.svelte'
	import ExitModalTrigger from './ExitModalTrigger.svelte'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'

	const exitModalState = getExitModalState()
</script>

<svelte:head>
	{#if $exitModalState?.open}
		<style>
			html {
				overflow: hidden;
			}
			body {
				overflow: auto;
				margin: 0;
				max-height: 100vh;
			}
		</style>
	{/if}
</svelte:head>

{#if $page.data.exitModal}
	<ExitModalTrigger />
{/if}

{#if $exitModalState?.open}
	<div
		class="hide-scrollbar animate-opacity fixed left-0 top-0 z-[10000] flex h-full w-full items-start justify-center overflow-y-scroll bg-neutral-300/20 backdrop-blur-sm"
	>
		<div
			class="absolute h-full w-full"
			role="none"
			on:click={() => {
				exitModalState.update((state) => ({
					...state,
					open: false
				}))
				setCookie('NO_EMOD', 'true', 30)
			}}
		/>
		<div
			class="animate-in relative mx-2 my-8 mt-2 grid w-full max-w-[860px] grid-cols-12 overflow-hidden rounded-xl shadow-lg sm:mt-20 sm:rounded-3xl"
		>
			<div
				class="relative col-span-12 flex items-center justify-center bg-white p-4 sm:col-span-7 sm:p-10 sm:pb-8"
			>
				<div class="transition-opacity {formStep === 'SENT' && 'pointer-events-none opacity-0'}">
					<h2 class="unstyled leading- pr-6 text-3.5xl font-semibold leading-tight sm:pr-0">
						{@html t('web.modals.exit.title')}
					</h2>
					<div class="mt-10 w-full">
						<ExitForm bind:formStep />
					</div>
				</div>
				<div
					class="pointer-events-none absolute flex flex-col items-center justify-center gap-4 pb-24 opacity-0 transition-opacity {formStep ===
						'SENT' && 'pointer-events-auto opacity-100'}"
				>
					<MailCheck size={64} color="#061c3e" />
					<p class="unstyled sm:x-12 px-6 text-center text-2xl font-semibold text-w-blue-950">
						{t('web.modals.exit.thankYou')}
					</p>
				</div>
			</div>
			<div class="col-span-12 flex flex-col items-center bg-w-blue-950 py-12 sm:col-span-5">
				<img
					src="/images/logos/woltair-red.svg"
					alt="Woltair"
					class="h-8"
					width="182"
					height="32"
				/>
				<p class="unstyled mt-6 text-white">{t('web.modals.exit.content.intro')}</p>
				<div class="mt-16 flex flex-col gap-6 text-center text-xl font-medium text-white">
					<p class="unstyled px-12">{t('web.modals.exit.content.1')}</p>
					<div class="h-[1px] bg-w-blue-800/30" />
					<p class="unstyled px-12">{t('web.modals.exit.content.2')}</p>
					<div class="h-[1px] bg-w-blue-800/30" />
					<p class="unstyled px-12">{t('web.modals.exit.content.3')}</p>
				</div>
				<p class="unstyled mt-16 text-white">{t('web.modals.exit.content.outro')}</p>
				<a
					href={CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE].phoneRef}
					class="unstyled mt-1 text-xl font-medium text-w-red-500 hover:underline"
					>{CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE].phoneNum}</a
				>
			</div>
			<button
				class="absolute right-0 top-0 rounded-bl-3xl px-4 py-3.5"
				on:click={() => {
					exitModalState.update((state) => ({
						...state,
						open: false
					}))
					setCookie('NO_EMOD', 'true', 30)
				}}><X color="#fff" class="hidden sm:block" /><X color="#061c3e" class="sm:hidden" /></button
			>
		</div>
	</div>
{/if}

<style>
	.animate-in {
		animation: slide-in 0.25s ease-out both;
	}

	.animate-opacity {
		animation: opacity-in 0.25s ease-out both;
	}

	@keyframes slide-in {
		0% {
			transform: translateY(-20%) scale(0.5);
			opacity: 0;
		}
		100% {
			transform: translateY(0) scale(1);
			opacity: 1;
		}
	}

	@keyframes opacity-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
