<script lang="ts">
	import { Field, Control, Label, FieldErrors } from 'formsnap'
	import { superForm, defaults } from 'sveltekit-superforms'
	import { zodClient, zod } from 'sveltekit-superforms/adapters'
	import { z } from 'zod'
	import { page } from '$app/stores'
	import { sendGTM } from '$lib/analytics'
	import { DOCUMENTS, PRIVACY_POLICY, ABOUT_US } from '$lib/routes'
	import {
		getExpertContactModalState,
		getMarketplaceDataState,
		getMarketplaceContactInputState
	} from '$lib/stores'
	import { t } from '$lib/translations'
	import { validatePhone, setCookie } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import type { Lead } from '$lib/types/core'
	import { ToggleGroup, Label as BitsLabel, Checkbox } from 'bits-ui'
	import { Check } from 'lucide-svelte'
	import { LEAD_SOURCE } from '$lib/consts'
	import { onMount } from 'svelte'

	export let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'

	const marketplaceDataState = getMarketplaceDataState()
	const expertContactModalState = getExpertContactModalState()
	const marketplaceContactInputState = getMarketplaceContactInputState()

	let errorMessage = ''

	const consentCheckboxGeneral =
		PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL' || PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT'

	export const schema = z.object({
		formType: z
			.string()
			.optional()
			.refine(() => true),
		email: z
			.string()
			.min(1, t('web.forms.email.required'))
			.pipe(z.string().email(t('web.forms.email.validate'))),
		phone: z
			.string()
			.min(1, t('web.forms.phone.required'))
			.pipe(
				z.string().refine(async (value) => {
					const validationResult = await validatePhone(value)
					return validationResult.isValid
				}, t('web.forms.phone.validate') || '')
			),
		zip: z
			.string()
			.min(1, t('web.forms.leaveContact.zip.required'))
			.refine(
				(value) =>
					(value.length === 6 && (value.includes('-') || value.includes('–'))) ||
					value.length === 5,
				{
					message: t('web.forms.leaveContact.zip.validation')
				}
			),
		marketingConsentGeneral: z
			.boolean()
			.default(false)
			.refine((value) => (consentCheckboxGeneral ? value : true), t('web.forms.field.required'))
	})

	const form = superForm(defaults(zod(schema)), {
		SPA: true,
		validators: zodClient(schema),
		onUpdate: async ({ form }) => {
			let leadUid = ''
			if (form.valid) {
				const { ...marketplaceData } = $marketplaceDataState
				formStep = 'SENDING'

				const defaultLeadSource =
					$formData.formType === 'pv'
						? LEAD_SOURCE.PV_MARKETPLACE_EXPERT
						: LEAD_SOURCE.HVAC_MARKETPLACE_EXPERT

				const body = {
					...marketplaceData,
					...$formData,
					leadSource: $expertContactModalState.leadSource || defaultLeadSource,
					pathname: $page.url.pathname,
					...(PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ' && {
						marketingConsentGeneral: true
					}),
					marketingConsentGeneral: true
				}

				const response = await fetch('/api/lead/create', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(body)
				})

				if (response.ok) {
					const data: Record<string, Lead> = await response.json()
					leadUid = data?.leadCreate?.uid
					formStep = 'SENT'

					expertContactModalState.update((state) => ({
						...state,
						step: 'THANK_YOU'
					}))

					sendGTM('advice_form_submit_appDrawer', { id: leadUid })

					if (leadUid)
						$formData.formType === 'pv'
							? setCookie('PV_LEAD_UID', leadUid || '', 30)
							: setCookie('HVAC_LEAD_UID', leadUid || '', 30)

					marketplaceContactInputState.update((state) => ({
						...state,
						email: $formData.email,
						phone: $formData.phone,
						zip: $formData.zip
					}))

					setCookie('MARKETPLACE_CONTACT_INPUT', JSON.stringify($marketplaceContactInputState), 30)
				} else {
					formStep = 'DEFAULT'
					errorMessage =
						t('web.forms.error.submit', {
							class: 'underline',
							link: `/${ABOUT_US}`
						}) || ''
				}
			}
		},
		onChange: () => {
			errorMessage = ''
		}
	})

	const { form: formData, enhance: enhance, errors } = form

	const setDefaultFormType = () => {
		if ($expertContactModalState.defaultFormType) {
			$formData.formType = $expertContactModalState.defaultFormType
		} else {
			$formData.formType = undefined
		}

		if ($marketplaceDataState.zip) $formData.zip = $marketplaceDataState.zip
	}

	// $: $expertContactModalState.open, setDefaultFormType()

	onMount(() => {
		setDefaultFormType()
		if ($marketplaceDataState.cityName) $formData.zip = $marketplaceDataState.cityName
		if ($marketplaceContactInputState.email) $formData.email = $marketplaceContactInputState.email
		if ($marketplaceContactInputState.phone) $formData.phone = $marketplaceContactInputState.phone
	})
</script>

<form use:enhance class="flex flex-col transition-opacity" method="POST">
	<div>
		<!-- <p class="unstyled text-sm text-w-blue-950">Vyberte zařízení</p> -->
		<ToggleGroup.Root
			class="relative mt-2 flex w-full gap-1 rounded-lg border border-neutral-200 bg-white p-1"
			type="single"
			bind:value={$formData.formType}
			onValueChange={(value) => {
				if (value !== 'hvac' && value !== 'pv') {
					$formData.formType = undefined
				}
			}}
		>
			<ToggleGroup.Item
				value="hvac"
				class="flex flex-1 items-center justify-center gap-2 rounded-md p-3  text-sm font-semibold text-w-blue-950 transition-colors hover:bg-neutral-100 data-[state=on]:bg-w-red-500 data-[state=on]:text-white"
				><img
					src={$formData.formType === 'hvac'
						? '/images/icons/white/heat_pump.svg'
						: '/images/icons/dark-blue/heat_pump.svg'}
					alt={t('web.forms.heatPump')}
					class="hidden aspect-square h-[1.4em] min-h-[16px] xs:block"
				/>{t('web.forms.heatPump')}</ToggleGroup.Item
			>
			<div
				class="absolute inset-x-0 inset-y-0 m-auto h-[70%] w-[1px] bg-neutral-200 transition-opacity {$formData.formType &&
					'opacity-0'}"
			/>
			<ToggleGroup.Item
				value="pv"
				class="flex flex-1 items-center justify-center gap-2 rounded-md p-3 text-sm font-semibold text-w-blue-950 transition-colors hover:bg-neutral-100 data-[state=on]:bg-w-red-500 data-[state=on]:text-white"
				><img
					src={$formData.formType === 'pv'
						? '/images/icons/white/flash.svg'
						: '/images/icons/dark-blue/flash.svg'}
					alt={t('web.forms.heatPump')}
					class="hidden aspect-square h-[1.4em] min-h-[16px] xs:block"
				/>{t('web.forms.photovoltaics')}</ToggleGroup.Item
			>
		</ToggleGroup.Root>
	</div>
	<Field {form} name="email">
		<Control let:attrs>
			<Label class="mt-6 text-sm">{t('web.forms.email')}</Label>

			<input
				{...attrs}
				type="text"
				bind:value={$formData.email}
				placeholder={t('web.forms.email.placeholder.example')}
				class="my-1 rounded-md border-black/10 px-4 py-3 transition-colors focus:border-w-blue-950 focus:ring-w-blue-950 active:border-w-blue-950 active:ring-w-blue-950 md:text-sm"
				class:border-w-red-500={$errors.email}
			/>
			<FieldErrors class="text-sm text-w-red-500" />
		</Control>
	</Field>

	<Field {form} name="phone">
		<Control let:attrs>
			<Label class="mt-2 text-sm">
				{t('web.forms.phone')}
			</Label>

			<input
				{...attrs}
				type="text"
				bind:value={$formData.phone}
				placeholder={t('web.forms.phone.placeholder')}
				class="my-1 rounded-md border-black/10 px-4 py-3 transition-colors focus:border-w-blue-950 focus:ring-w-blue-950 active:border-w-blue-950 active:ring-w-blue-950 md:text-sm"
				class:border-w-red-500={$errors.phone}
			/>
			<FieldErrors class="text-sm text-w-red-500" />
		</Control>
	</Field>

	<Field {form} name="zip">
		<Control let:attrs>
			<Label class="mt-2 text-sm">
				{@html t('web.forms.zipcode')}
			</Label>

			<input
				{...attrs}
				type="text"
				bind:value={$formData.zip}
				data-testid="input-hvac-zip"
				placeholder={t('web.forms.zip.placeholder')}
				class="my-1 rounded-md border-black/10 px-4 py-3 transition-colors focus:border-w-blue-950 focus:ring-w-blue-950 active:border-w-blue-950 active:ring-w-blue-950 md:text-sm"
				class:border-w-red-500={$errors.zip}
			/>
			<FieldErrors class="text-sm text-w-red-500" />
		</Control>
	</Field>

	{#if consentCheckboxGeneral}
		<Field {form} name="marketingConsentGeneral">
			<Control>
				<div class="flex items-center gap-x-4 pt-4">
					<Checkbox.Root
						id="terms"
						data-testid="marketing-consent"
						aria-labelledby="terms-label"
						class="flex aspect-square h-8 w-8 items-center justify-center rounded-md border  bg-white transition-colors data-[state=checked]:border-w-red-500 data-[state=checked]:bg-w-red-500 {$errors.marketingConsentGeneral
							? 'border-w-red-500'
							: 'border-neutral-300'}"
						bind:checked={$formData.marketingConsentGeneral}
					>
						<Checkbox.Indicator class="text-background inline-flex items-center justify-center">
							<Check size={24} color="white" />
						</Checkbox.Indicator>
					</Checkbox.Root>
					<BitsLabel.Root
						id="terms-label"
						for="terms"
						class="flex cursor-pointer flex-col text-sm font-medium leading-none"
					>
						<span class="text-sm font-semibold text-neutral-500"
							>{@html t('web.forms.conditions.asCheckbox', {
								class: 'text-w-red-500',
								link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
							})}</span
						>
					</BitsLabel.Root>
				</div>
				<FieldErrors class="pb-4 text-sm text-w-red-500" />
			</Control>
		</Field>
	{/if}

	<button
		class:pointer-events-none={formStep === 'SENDING'}
		class:opacity-70={formStep === 'SENDING'}
		data-testid="header-form-hvac-submit"
		type="submit"
		class="btn-new btn-new-blue z-10 mt-2 w-full whitespace-normal rounded-md sm:rounded-lg"
	>
		{#if formStep === 'SENDING'}
			<img src="/images/icons/loader-2.svg" class="w-[1.25em] animate-spin" alt="Loader icon" />{t(
				'web.forms.button.sendingForm'
			)}
		{:else}
			{t('web.forms.submit')}
		{/if}
	</button>

	{#if errorMessage}
		<p class="mt-2 text-center text-sm text-w-red-500">{@html errorMessage}</p>
	{/if}
	<p class="unstyled my-4 text-center text-xs font-light !leading-tight text-neutral-500">
		{@html t('web.forms.conditions', {
			class: 'text-primary-500',
			link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
		})}
	</p>
</form>
