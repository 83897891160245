<script lang="ts">
	import { Field, Control, Label, FieldErrors } from 'formsnap'
	import { superForm, defaults } from 'sveltekit-superforms'
	import { zodClient, zod } from 'sveltekit-superforms/adapters'
	import { z } from 'zod'
	import { page } from '$app/stores'
	import { sendGTM } from '$lib/analytics'
	import { INSTALLATION_CATEGORY } from '$lib/consts'
	import { URLPART_TYPE_LISTING } from '$lib/consts'
	import { DOCUMENTS, PRIVACY_POLICY } from '$lib/routes'
	import { getExitModalState } from '$lib/stores'
	import { t } from '$lib/translations'
	import { validatePhone } from '$lib/utils'
	import { setCookie } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'

	const exitModalState = getExitModalState()

	let deviceType = null
	if ($page.url.pathname.includes(URLPART_TYPE_LISTING['hvac'])) deviceType = 'hvac'
	else if ($page.url.pathname.includes(URLPART_TYPE_LISTING['pv'])) deviceType = 'pv'

	export const schema = z.object({
		email: z
			.string()
			.min(1, t('web.forms.email.required'))
			.pipe(z.string().email(t('web.forms.email.validate'))),
		phone: z
			.string()
			.min(1, t('web.forms.phone.required'))
			.pipe(
				z.string().refine(async (value) => {
					const validationResult = await validatePhone(value)
					return validationResult.isValid
				}, t('web.forms.phone.validate') || '')
			),
		zip: z
			.string()
			.min(1, t('web.forms.leaveContact.zip.required'))
			.pipe(
				z.string().refine((value) => {
					if (value.length < 5) return false
					return /^[0-9-–]+$/.test(value)
				}, t('web.forms.leaveContact.zip.validation'))
			)
	})

	const form = superForm(defaults(zod(schema)), {
		SPA: true,
		validators: zodClient(schema),
		onUpdate: async ({ form }) => {
			let leadUid = ''
			if (form.valid) {
				formStep = 'SENDING'
				const response = await fetch('/api/lead/create', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						...$formData,
						leadSource: 'EXIT_MODAL',
						...(deviceType && {
							...(deviceType === 'pv'
								? { installationProductType: INSTALLATION_CATEGORY['FOTOVOLTAIKA'] }
								: { installationProductType: INSTALLATION_CATEGORY['TEPELNE_CERPADLO'] })
						}),
						...(PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ' && {
							marketingConsentGeneral: true
						})
					})
				})

				if (response.ok) {
					const data: any = await response.json()
					leadUid = data?.leadCreate?.uid
					formStep = 'SENT'

					if (deviceType === 'hvac') setCookie('HVAC_LEAD_UID', leadUid, 30)
					else if (deviceType === 'pv') setCookie('PV_LEAD_UID', leadUid, 30)

					sendGTM(
						'exit_modal_submit',
						{ id: leadUid },
						{
							calculator: {
								category: deviceType,
								email: $formData.email,
								phone: $formData.phone,
								zip_code: $formData.zip
							}
						}
					)

					setCookie('NO_EMOD', 'true', 30)
					setTimeout(() => {
						exitModalState.update((state) => ({ ...state, open: false }))
					}, 8000)
				}
			}
		}
	})

	const { form: formData, enhance: enhance, errors } = form
</script>

<form use:enhance class="flex flex-col transition-opacity" method="POST">
	<Field {form} name="email">
		<Control let:attrs>
			<Label class="text-sm">{t('web.forms.email')}</Label>

			<input
				{...attrs}
				type="text"
				bind:value={$formData.email}
				placeholder={t('web.forms.email.placeholder.example')}
				class="my-1 rounded-md border-black/10 px-4 py-3"
				class:border-w-red-500={$errors.email}
			/>
			<FieldErrors class="text-sm text-w-red-500" />
		</Control>
	</Field>

	<Field {form} name="phone">
		<Control let:attrs>
			<Label class="mt-3 text-sm">
				{t('web.forms.phone')}
			</Label>

			<input
				{...attrs}
				type="text"
				bind:value={$formData.phone}
				placeholder={t('web.forms.phone.placeholder')}
				class="my-1 rounded-md border-black/10 px-4 py-3"
				class:border-w-red-500={$errors.phone}
			/>
			<FieldErrors class="text-sm text-w-red-500" />
		</Control>
	</Field>

	<Field {form} name="zip">
		<Control let:attrs>
			<Label class="mt-3 text-sm">
				{@html t('web.forms.zipcode')}
			</Label>

			<input
				{...attrs}
				type="text"
				bind:value={$formData.zip}
				data-testid="input-hvac-zipcode"
				placeholder={t('web.forms.zipcode.placeholder')}
				class="my-1 rounded-md border-black/10 px-4 py-3"
				class:border-w-red-500={$errors.zip}
			/>
			<FieldErrors class="text-sm text-w-red-500" />
		</Control>
	</Field>
	<button
		class:pointer-events-none={formStep === 'SENDING'}
		class:opacity-70={formStep === 'SENDING'}
		data-testid="header-form-hvac-submit"
		type="submit"
		class="btn-new btn-new-blue mt-6 w-full whitespace-normal rounded-md sm:rounded-lg"
	>
		{#if formStep === 'SENDING'}
			<img src="/images/icons/loader-2.svg" class="w-[1.25em] animate-spin" alt="Loader icon" />{t(
				'web.forms.button.sendingForm'
			)}
		{:else}
			{t('web.modals.exit.button')}
		{/if}
	</button>
	<p class="unstyled my-6 text-center text-sm font-light !leading-tight text-neutral-500">
		{@html t('web.forms.conditions', {
			class: 'text-primary-500',
			link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
		})}
	</p>
</form>
