<script lang="ts">
	import { getNewsletterPopupState } from '$lib/stores'
	import { t } from '$lib/translations'
	import { setCookie } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import { superForm, defaults } from 'sveltekit-superforms'
	import { z } from 'zod'
	import { zodClient, zod } from 'sveltekit-superforms/adapters'
	import { Field, Control, FieldErrors } from 'formsnap'
	import { DOCUMENTS, PRIVACY_POLICY, ABOUT_US } from '$lib/routes'
	import { Checkbox, Label as BitsLabel } from 'bits-ui'
	import { Check } from 'lucide-svelte'

	const newsletterPopupState = getNewsletterPopupState()

	export let showWacpBanner: boolean | undefined = false
	export let showNewsletterPopup: boolean | undefined = true

	const consentsAsCheckboxes = PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'

	let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'

	let errorMessage = ''

	export const schemaNew = z.object({
		email: z
			.string()
			.min(1, t('web.forms.email.required'))
			.pipe(z.string().email(t('web.forms.email.validate'))),
		marketingConsentGeneral: z
			.boolean()
			.default(false)
			.refine((value) => (consentsAsCheckboxes ? value : true), t('web.forms.field.required')),
		marketingConsentEmail: z
			.boolean()
			.default(false)
			.refine((value) => (consentsAsCheckboxes ? value : true), t('web.forms.field.required'))
	})

	const form = superForm(defaults(zod(schemaNew)), {
		SPA: true,
		validators: zodClient(schemaNew),
		onUpdate: async ({ form }) => {
			if (form.valid) {
				try {
					const response = await fetch('/api/lead/create', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							...$formData,
							...(consentsAsCheckboxes && {
								marketingConsentGeneral: true,
								marketingConsentEmail: true
							}),
							leadSource: 'NEWSLETTER_POPUP'
						})
					})

					if (response.ok) {
						formStep = 'SENT'
						setTimeout(() => {
							newsletterPopupState.update((state) => ({
								...state,
								open: false
							}))
							setCookie('NWSLTPP', 'false', 30)
						}, 2000)
					} else {
						formStep = 'DEFAULT'
					}
				} catch (error: unknown) {
					formStep = 'DEFAULT'
					errorMessage =
						t('web.forms.error.submit', {
							class: 'underline',
							link: `/${ABOUT_US}`
						}) || ''
				}
			}
		},
		onChange: () => {
			errorMessage = ''
		}
	})

	const { form: formData, enhance: enhance, errors } = form
</script>

{#if $newsletterPopupState?.open && !showWacpBanner && showNewsletterPopup}
	<div
		class="fixed inset-x-0 bottom-20 z-[999] mx-auto flex justify-center px-2 3xs:bottom-16 md:bottom-8"
	>
		<div
			class="{formStep === 'SENT'
				? 'fadeOutDown'
				: 'fadeInUp'} relative w-full max-w-[500px] overflow-hidden rounded-xl bg-w-blue-950 px-4 py-4 shadow-2xl md:mx-auto md:rounded-2xl md:p-5 md:pt-4"
		>
			<div class="flex items-start justify-between gap-3">
				<h4 class="unstyled h4 mb-0 mt-0 text-white">{@html t('web.modals.newsletter.title')}</h4>
				<button
					on:click={() => {
						newsletterPopupState.update((state) => ({
							...state,
							open: false
						}))
						setCookie('NWSLTPP', 'false', 1)
					}}
					><img
						src="/images/icons/close-x.svg"
						alt="Close icon"
						class="w-3 transition-opacity hover:opacity-80"
					/></button
				>
			</div>
			<p class="unstyled mt-2 text-xs leading-normal text-white opacity-70">
				<span class="hidden md:inline">{@html t('web.modals.newsletter.text')}</span>
				<span class="md:hidden">{@html t('web.modals.newsletter.text.mobile')}</span>
			</p>
			<form use:enhance class="flex flex-col transition-opacity" method="POST">
				<div class="mt-2 flex w-full gap-2">
					<Field {form} name="email">
						<Control let:attrs>
							<input
								{...attrs}
								type="text"
								bind:value={$formData.email}
								placeholder={t('web.forms.email.placeholder.example')}
								class="w-full rounded-md text-xs placeholder:opacity-50 md:text-sm"
								class:border-w-red-500={$errors.email}
							/>
						</Control>
					</Field>
					<button
						class="btn-new btn-new-red point rounded-md text-xs"
						type="submit"
						class:pointer-events-none={formStep === 'SENDING' || formStep === 'SENT'}
						>{#if formStep === 'SENDING'}
							<img
								src="/images/icons/loader-2.svg"
								class="w-[1.25em] animate-spin"
								alt="Loader icon"
							/>{@html t('web.forms.submit.sending')}
						{:else if formStep === 'SENT'}
							<img src="/images/icons/tick.svg" class="w-[1.25em]" alt="Loader icon" />
							{@html t('web.forms.submit.sent')}
						{:else}
							<span class="hidden md:inline">{@html t('web.modals.newsletter.button')}</span>
							<span class="md:hidden">{@html t('web.modals.newsletter.button.mobile')}</span>
						{/if}</button
					>
				</div>
				{#if $errors.email}
					<p class="unstyled pt-1 text-xs text-w-red-500">{$errors.email}</p>
				{/if}

				{#if consentsAsCheckboxes}
					<p class="unstyled mt-3 text-[10px] font-light text-white opacity-70">
						{t('web.forms.conditions.withOptions')}
					</p>
					<Field {form} name="marketingConsentEmail">
						<Control>
							<div class="flex items-center gap-x-2 pb-1 pt-4">
								<Checkbox.Root
									id="email-terms"
									data-testid="marketing-consent"
									aria-labelledby="email-terms-label"
									class="flex aspect-square h-5 w-5 items-center justify-center rounded-md border  bg-white transition-colors data-[state=checked]:border-w-red-500 data-[state=checked]:bg-w-red-500 {$errors.marketingConsentEmail
										? 'border-w-red-500'
										: 'border-neutral-300'}"
									bind:checked={$formData.marketingConsentEmail}
								>
									<Checkbox.Indicator
										class="text-background inline-flex items-center justify-center"
									>
										<Check size={16} color="white" />
									</Checkbox.Indicator>
								</Checkbox.Root>
								<BitsLabel.Root
									id="email-terms-label"
									for="email-terms"
									class="flex cursor-pointer flex-col text-sm font-medium leading-none"
								>
									<span class="unstyled text-xs font-light text-white opacity-70"
										>{t('web.forms.email')}</span
									>
								</BitsLabel.Root>
							</div>
							<FieldErrors class=" text-xs text-w-red-500 " />
						</Control>
					</Field>
					<Field {form} name="marketingConsentGeneral">
						<Control>
							<div class="flex items-center gap-x-2 pb-1 pt-2">
								<Checkbox.Root
									id="general-terms"
									data-testid="marketing-consent"
									aria-labelledby="general-terms-label"
									class="flex aspect-square h-5 w-5 items-center justify-center rounded-md border bg-white transition-colors data-[state=checked]:border-w-red-500 data-[state=checked]:bg-w-red-500 {$errors.marketingConsentGeneral
										? 'border-w-red-500'
										: 'border-neutral-300'}"
									bind:checked={$formData.marketingConsentGeneral}
								>
									<Checkbox.Indicator
										class="text-background inline-flex items-center justify-center"
									>
										<Check size={16} color="white" />
									</Checkbox.Indicator>
								</Checkbox.Root>
								<BitsLabel.Root
									id="general-terms-label"
									for="general-terms"
									class="flex cursor-pointer flex-col text-sm font-medium leading-none"
								>
									<span class="unstyled text-xs font-light text-white opacity-70"
										>{@html t('web.forms.conditions.asCheckbox', {
											class: 'text-w-red-500',
											link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
										})}</span
									>
								</BitsLabel.Root>
							</div>
							<FieldErrors class="text-xs text-w-red-500" />
						</Control>
					</Field>
				{:else}
					<p class="unstyled mt-3 text-[10px] font-light text-white opacity-70">
						{@html t('web.forms.conditions', {
							link: `/${DOCUMENTS}/${PRIVACY_POLICY}`,
							class: 'underline'
						})}
					</p>
				{/if}
			</form>
			{#if errorMessage}
				<p class="unstyled pt-2 text-center text-sm text-w-red-500">{errorMessage}</p>
			{/if}
		</div>
	</div>
{/if}

<style lang="scss">
	@keyframes fadeInUp {
		from {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}

		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	.fadeInUp {
		transform: translate3d(0, 100%, 0);
		animation-name: fadeInUp;
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
	}

	@keyframes fadeOutDown {
		from {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
		to {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
	}

	.fadeOutDown {
		animation-name: fadeOutDown;
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
		animation-delay: 1s;
	}
</style>
