<script lang="ts">
	import { string, object, bool } from 'yup'
	import { sendGTM } from '$lib/analytics'
	import { DOCUMENTS, PRIVACY_POLICY } from '$lib/routes'
	import { t } from '$lib/translations'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let data: {
		data: {
			attributes: {
				footerLinks: {
					title: string
					subLinks: {
						text: string
						path: string
					}[]
				}[]
				socialLinks: {
					link: string
					type: 'FACEBOOK' | 'INSTAGRAM' | 'LINKEDIN' | 'X' | 'YOUTUBE'
				}[]
				documents: {
					text: string
					path: string
				}[]
				companyName: string
				legalText: string
			}
		}
	}

	const { footerLinks, socialLinks, documents, companyName, legalText } =
		data.data?.attributes || {}

	let formErrors: Record<string, any> = {}
	let formData: Record<string, any> = {
		email: '',
		...(PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL' && {
			marketingConsentEmail: false,
			marketingConsentGeneral: false
		}),
		...(PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT' && {
			marketingConsentGeneral: false
		})
	}
	let formSent = false
	let formDone = false

	const schema = object({
		email: string().email(t('web.forms.email.validate')).required(t('web.forms.email.required')),
		marketingConsentGeneral: bool()
			.oneOf([true], t('web.woltadvisor.conditions.validate'))
			.required(t('web.woltadvisor.conditions.validate')),
		marketingConsentEmail: bool()
			.oneOf([true], t('web.woltadvisor.conditions.validate'))
			.required(t('web.woltadvisor.conditions.validate'))
	})

	async function validateField(fieldName: string, value: string) {
		try {
			await schema.validateAt(fieldName, { [fieldName]: value })
			formErrors[fieldName] = ''
		} catch (error: any) {
			formErrors[fieldName] = error.message
		}
	}

	async function handleSubmit(event: Record<string, any>) {
		event.preventDefault()

		try {
			formErrors = {}
			await Promise.all(
				Object.entries(formData).map(async ([key, value]) => {
					await validateField(key, value.toString() || '')
				})
			)
			if (Object.values(formErrors).some((error) => error)) throw new Error('Validation failed')

			formSent = true

			const response = await fetch('/api/lead/create', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					...formData,
					...((PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ' ||
						PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_DE') && { marketingConsentGeneral: true }),
					leadSource: 'NEWSLETTER'
				})
			})
			if (response.ok) {
				formSent = false
				formDone = true
				const data = await response.json()
				sendGTM(
					'footer_newsletter',
					{},
					{
						content: {
							email: formData.email
						},
						transaction_id: data?.leadCreate?.uid || '',
						_clear: true
					}
				)
			} else {
				formSent = false
				formDone = false
			}
		} catch (error: any) {
			if (error.inner) {
				if (error.inner.length > 0)
					error.inner.forEach((err: any) => {
						formErrors[err.path] = err.message
					})
			}
		}
	}

	const getIconUrl = (type: string) => {
		switch (type) {
			case 'FACEBOOK':
				return '/images/icons/social-media/facebook.svg'
			case 'INSTAGRAM':
				return '/images/icons/social-media/instagram.svg'
			case 'LINKEDIN':
				return '/images/icons/social-media/linkedin.svg'
			case 'X':
				return '/images/icons/social-media/x.svg'
			case 'YOUTUBE':
				return '/images/icons/social-media/youtube.svg'
		}
	}
</script>

<div class="w-full border-y border-neutral-200">
	<div class="page-wrapper flex h-full flex-col gap-x-16 md:flex-row">
		<div
			class="order-2 grid w-full grid-cols-1 gap-x-16 gap-y-12 pt-10 2xs:grid-cols-2 md:order-1 md:w-8/12 md:pb-12 lg:w-9/12 lg:grid-cols-4"
		>
			{#if footerLinks?.length > 0}
				{#each footerLinks as link}
					<div class="flex w-full max-w-48 flex-col gap-4 overflow-hidden md:w-fit">
						{#if link.title}
							<p class="unstyled text-sm font-semibold text-neutral-600">{link.title}</p>
						{/if}

						{#if link?.subLinks?.length > 0}
							{#each link.subLinks as subLink}
								<a
									href={subLink.path}
									class="w-full border-neutral-300 text-sm text-neutral-600 hover:text-neutral-900"
									>{subLink.text}</a
								>
							{/each}
						{/if}
					</div>
				{/each}
			{/if}
		</div>
		<div
			class="order-1 flex w-full flex-col gap-6 pt-10 md:order-2 md:w-4/12 md:items-end md:pb-12 lg:w-3/12"
		>
			<img
				src="/images/logos/woltair-red.svg"
				alt="Woltair logo"
				class="min-w-[158px]"
				width="158"
				height="28"
			/>
			<div class="relative min-h-12 w-full">
				<form on:submit={handleSubmit} class="w-full transition-opacity">
					<div class="relative">
						<input
							type="text"
							name="email"
							on:change={() => validateField('email', formData.email)}
							bind:value={formData.email}
							class="w-full rounded-md border-none bg-neutral-100 px-4 py-3.5 text-xs text-neutral-600 placeholder:text-black/40 md:text-sm"
							class:hidden={formSent || formDone}
							placeholder={t('web.forms.email.newsletter')}
						/>
						<div
							class="text-neutral-6 flex h-12 w-full items-center rounded-md border-none text-xs md:justify-end md:text-sm"
							class:hidden={!(formSent || formDone)}
						>
							<span
								class="absolute ml-0 flex gap-2 transition-opacity {formSent
									? 'opacity-100'
									: 'opacity-0'}"
								><img
									src="/images/icons/loader-2-black.svg"
									class="w-[1.25em] animate-spin"
									alt="Loader icon"
								/>{t('web.forms.button.sendingForm')}</span
							>
							<span
								class="absolute ml-0 flex gap-2 transition-opacity {formDone
									? 'opacity-100'
									: 'opacity-0'}"
								><img src="/images/icons/tick-black.svg" alt="Tick icon" class="w-[1.25em]" />{t(
									'web.forms.button.formSent'
								)}</span
							>
						</div>

						<button
							class="absolute right-0 top-0 h-full w-10 rounded-md bg-neutral-100 hover:bg-neutral-200 focus:bg-neutral-200"
							class:hidden={formSent || formDone}
							><img
								src="/images/icons/arrow/next-black-simple.svg"
								alt=""
								class="inset-x-0 mx-auto aspect-square h-3 opacity-80"
							/></button
						>
					</div>
					<p class="mt-1 text-xs text-w-red-500" class:hidden={!formErrors['email']}>
						{formErrors['email']}
					</p>
					<div
						class:pointer-action-none={formSent || formDone}
						class:opacity-0={formSent || formDone}
					>
						{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ' || PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_DE'}
							<p class="mt-2 text-xs text-black/60">
								{@html t('web.forms.conditions', {
									class: 'text-primary-500',
									link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
								})}
							</p>
						{/if}
						{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'}
							<p class="mt-2 text-xs text-black/60">
								{@html t('web.forms.conditions.withOptions', {
									class: 'text-primary-500',
									link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
								})}
							</p>
						{/if}
						{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'}
							<div class="mt-3 flex items-center">
								<input
									type="checkbox"
									name="marketingConsentEmail"
									id="marketingConsentEmail"
									bind:checked={formData['marketingConsentEmail']}
									class="checkbox mr-2"
									class:!border-primary-500={formErrors['marketingConsentEmail']}
								/>
								<label for={'marketingConsentEmail'} class="checkbox-label text-xs text-neutral-500"
									>{t('web.forms.email')}</label
								>
							</div>
							{#if formErrors['marketingConsentEmail']}
								<p class="mt-1 text-xs text-primary-500">{formErrors['marketingConsentEmail']}</p>
							{/if}
						{/if}
						{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT' || PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'}
							<div class="mt-2 flex items-center">
								<input
									type="checkbox"
									name="marketingConsentGeneral"
									id="marketingConsentGeneral"
									bind:checked={formData['marketingConsentGeneral']}
									class="checkbox mr-2"
									class:!border-primary-500={formErrors['marketingConsentGeneral']}
								/>
								<label
									for={'marketingConsentGeneral'}
									class="checkbox-label text-xs text-neutral-500"
								>
									{@html t('web.forms.conditions.asCheckbox', {
										class: 'text-primary-500',
										link: `/${DOCUMENTS}/${PRIVACY_POLICY}`
									})}</label
								>
							</div>
							{#if formErrors['marketingConsentGeneral']}
								<p class="mt-1 text-xs text-primary-500">{formErrors['marketingConsentGeneral']}</p>
							{/if}
						{/if}
					</div>
				</form>
			</div>
			{#if socialLinks?.length > 0}
				<div class="ml-auto hidden w-full gap-6 md:flex">
					{#each socialLinks as link}
						{@const iconUrl = getIconUrl(link.type)}
						<a href={link.link}
							><img src={iconUrl} alt={link.type} class="h-6 min-h-6" width="24" height="24" /></a
						>
					{/each}
				</div>
			{/if}

			<div class="mt-auto hidden md:block">
				<p class="mt-4 whitespace-nowrap text-sm font-semibold text-neutral-600">
					©{new Date().getFullYear()}
					{companyName}
				</p>
			</div>
		</div>
		{#if socialLinks?.length > 0}
			<div class="order-3 flex w-full gap-3 pt-12 3xs:gap-6 md:hidden">
				{#each socialLinks as link}
					{@const iconUrl = getIconUrl(link.type)}
					<a href={link.link}
						><img src={iconUrl} alt={link.type} class="h-6 min-h-6" width="24" height="24" /></a
					>
				{/each}
			</div>
		{/if}

		<div class="order-4 mr-auto flex w-full justify-end pb-6 pt-4 md:hidden">
			<p class="mt-4 text-sm font-semibold text-neutral-600">
				©{new Date().getFullYear()}
				{companyName}
			</p>
		</div>
	</div>
</div>

<div class="page-wrapper mt-6 flex flex-col items-center gap-6 pb-12 md:mt-12">
	<p class="text-sm leading-loose text-neutral-400 md:mx-4 md:text-center">
		{legalText}
	</p>

	{#if documents?.length > 0}
		<div
			class="flex w-full flex-col flex-wrap items-start justify-center gap-5 md:mx-0 md:w-fit md:flex-row md:items-center md:gap-x-10 md:px-4"
		>
			{#each documents as document}
				{@const externalLink = document.path.includes('http')}
				<a
					href={document.path}
					target={externalLink ? '_blank' : ''}
					class="text-sm text-neutral-400 hover:text-neutral-600">{document.text}</a
				>
			{/each}
		</div>
	{/if}
</div>
