<script lang="ts">
	import { BROWSER } from 'esm-env'
	import { onMount } from 'svelte'
	import { page } from '$app/stores'
	import { sendGTM } from '$lib/analytics'
	import { t } from '$lib/translations'
	import { getExpertContactModalState } from '$lib/stores'

	export let drawer: Boolean
	export let data: {
		data: {
			attributes: {
				showLogin: boolean
				showPhone: boolean
				showExpertCallButton: boolean
				navLinks: {
					text: string
					path: string
					description: string
					subLinks: {
						text: string
						path: string
						description: string
					}[]
				}[]
			}
		}
	}

	const expertContactModalState = getExpertContactModalState()

	const navLinks = data.data.attributes.navLinks
	const showLogin = data.data.attributes.showLogin
	const showPhone = data.data.attributes.showPhone
	const showExpertCallButton = data.data.attributes.showExpertCallButton

	$: userLoggedIn = BROWSER && $page.data?.user?.name

	let showSubmenu = false
	let activeSubmenu = 0
	let offsetTop = 78
	let wrapperElement: HTMLElement
	let menuElement: HTMLElement
	let subMenuElement: HTMLElement

	export const toggleDrawer = () => {
		drawer = !drawer
		menuElement.scroll(0, 0)
		subMenuElement.scroll(0, 0)
		offsetTop = getTopOffset()
		if (showSubmenu) showSubmenu = false
	}

	function getTopOffset() {
		return wrapperElement.getBoundingClientRect().top
	}

	onMount(() => {
		offsetTop = getTopOffset()
	})
</script>

<svelte:window
	on:resize={() => {
		offsetTop = getTopOffset()
	}}
/>

<svelte:head>
	{#if drawer}
		<style>
			body {
				overflow: hidden;
				position: relative;
				height: 100%;
			}
		</style>
	{/if}
</svelte:head>
<div
	class="hide-scrollbar relative inset-x-0 right-0 z-50 ml-auto mr-0 h-full min-h-fit max-w-[768px] overflow-hidden transition-all md:w-full"
	style={offsetTop ? `height: calc(100dvh - ${offsetTop}px)` : `height: 100vh`}
	bind:this={wrapperElement}
	class:pointer-events-none={!drawer}
	class:opacity-0={!drawer}
>
	<div
		class="menu-background absolute z-50 flex h-full min-h-fit w-[200%] translate-x-full scroll-mb-20 scroll-pt-20 backdrop-blur-xl transition-all"
		class:!translate-x-0={drawer}
		class:!translate-x-[-50%]={showSubmenu}
	>
		<div class="hide-scrollbar h-full w-1/2 overflow-scroll" bind:this={menuElement}>
			<div class="flex h-fit flex-col">
				{#if showPhone}
					<div class="my-4 flex">
						<a
							href={`tel:${t('woltairPhone')}`}
							class="mx-7 flex w-full items-center justify-center gap-4 rounded-lg bg-w-blue-950/[.07] p-5 text-lg font-semibold active:bg-w-blue-950/[.2]"
							on:click={() =>
								sendGTM(
									'call_to_action',
									{},
									{
										content: {
											type: 'click_call_me'
										},
										_clear: true
									}
								)}
						>
							<img
								src="/images/icons/phone-black.svg"
								class="aspect-square h-[1em]"
								alt="phone icon"
							/>{t('woltairPhoneFormatted')}
						</a>
					</div>
				{/if}
				{#each navLinks as link, i}
					{#if link.subLinks?.length}
						<button
							class="flex w-full items-center justify-between border-black/[.07] p-7 text-left text-w-blue-950 active:bg-neutral-200"
							class:border-t={i !== 0}
							on:click={() => {
								activeSubmenu = i
								showSubmenu = true
							}}
						>
							{link.text}<img
								src="/images/icons/arrow/next-black-simple.svg"
								class="aspect-square h-[.7em]"
								alt="arrow icon – next"
							/>
						</button>
					{:else}
						<a
							href={link.path}
							on:click={() => toggleDrawer()}
							class="w-full border-black/[.07] p-7 text-left text-w-blue-950 active:bg-neutral-200"
							class:border-t={i !== 0}>{link.text}</a
						>
					{/if}
				{/each}
				<div class="mb-7 mt-4 flex w-full flex-col gap-3">
					{#if showExpertCallButton}
						<div class="mt-auto flex">
							<div
								role="button"
								tabindex="0"
								on:click={() => {
									toggleDrawer()
									expertContactModalState.update((state) => ({
										...state,
										open: true
									}))
								}}
								on:keydown={(e) => {
									if (e.key == 'Enter') {
										toggleDrawer()
										expertContactModalState.update((state) => ({
											...state,
											open: true
										}))
									}
								}}
								class="mx-7 flex w-full items-center justify-center gap-4 rounded-lg bg-primary-500 p-5 font-medium text-white active:bg-w-blue-950/[0.8]"
							>
								{t('web.marketplace.button.expertHelp')}
							</div>
						</div>
					{/if}
					{#if showLogin}
						<div class="mt-auto flex">
							<a
								href="https://account.{$page.url.hostname.replace('www.', '')}/{userLoggedIn
									? 'orders'
									: 'login'}"
								class="mx-7 flex w-full items-center justify-center gap-4 rounded-lg bg-w-blue-950 p-5 font-medium text-white active:bg-w-blue-950/[0.8]"
							>
								<img
									src="/images/icons/customer-white.svg"
									class="aspect-square h-[1em]"
									alt="customer icon"
								/>{userLoggedIn
									? $page.data?.user?.name || t('web.routes.userAccount')
									: t('web.routes.login')}
							</a>
						</div>
					{/if}
				</div>
			</div>
		</div>
		<div class="hide-scrollbar relative h-full w-1/2 overflow-scroll" bind:this={subMenuElement}>
			{#each navLinks as link, i}
				<div
					class="absolute w-full {activeSubmenu !== i
						? 'pointer-events-none h-0 overflow-hidden opacity-0'
						: ''} flex flex-col"
				>
					<button
						class="flex w-full items-center gap-4 px-7 pt-4 text-w-blue-950 active:text-w-red-500"
						on:click={() => {
							showSubmenu = false
						}}
						><img
							src="/images/icons/arrow/next-black-simple.svg"
							class="aspect-square h-[.7em] rotate-180"
							alt="arrow icon – back"
						/>{t('web.nav.back')}</button
					>
					<h3 class="unstyled px-7 pb-7 pt-4 text-2.5xl font-semibold text-w-blue-950">
						{link.text}
					</h3>
					<a
						href={link.path}
						on:click={() => toggleDrawer()}
						class="w-full border-y border-black/[.07] p-7 text-left text-w-blue-950 active:bg-neutral-200"
					>
						<h4 class="unstyled text-xl font-semibold text-w-blue-950">{link.text}</h4>
						{#if link.description}
							<p class="mt-4 text-sm leading-relaxed text-black opacity-70">
								{link.description}
							</p>
						{/if}
						<p class="mt-6 flex items-center gap-4 font-medium text-w-blue-950">
							<img
								src="/images/icons/woltair-ff-logo.svg"
								class="aspect-square h-[1em]"
								alt="Woltair logo"
							/>{t('web.nav.learnMore')}
						</p></a
					>
					{#each link.subLinks as subLink}
						<a
							href={subLink.path}
							class="w-full border-b border-black/[.07] p-7 text-left text-w-blue-950 active:bg-neutral-200"
							on:click={() => toggleDrawer()}
						>
							<h4 class="unstyled text-xl font-semibold text-w-blue-950">{subLink.text}</h4>
							{#if subLink.description}
								<p class="mt-4 text-sm leading-relaxed text-black opacity-70">
									{subLink.description}
								</p>
							{/if}
							<p class="mt-6 flex items-center gap-4 font-medium text-w-blue-950">
								<img
									src="/images/icons/woltair-ff-logo.svg"
									class="aspect-square h-[1em]"
									alt="Woltair logo"
								/>{t('web.nav.learnMore')}
							</p>
						</a>
					{/each}
				</div>
			{/each}
		</div>
	</div>
</div>

<style>
	.menu-background {
		background: rgb(255, 255, 255);
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.9) 15%,
			rgba(255, 255, 255, 0.9) 100%
		);
	}
</style>
