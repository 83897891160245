<script lang="ts">
	import { getExitModalState, getWebSessionState } from '$lib/stores'
	import { getCookie } from '$lib/utils'

	const exitModalState = getExitModalState()
	const webSessionState = getWebSessionState()
</script>

<div
	class="absolute top-0 z-[10000] h-2 w-full bg-transparent"
	role="none"
	on:mouseenter={() => {
		const conditions =
			getCookie('NO_EMOD') !== 'true' &&
			!getCookie('HVAC_LEAD_UID') &&
			!getCookie('PV_LEAD_UID') &&
			!getCookie('LEAD_UID') &&
			($webSessionState.getTimeSinceMounted() > 180 || $webSessionState.pagesVisited > 4)
		if (conditions)
			exitModalState.update((state) => ({
				...state,
				open: true
			}))
	}}
/>
