<script lang="ts">
	import { onMount } from 'svelte'
	import { beforeNavigate } from '$app/navigation'
	import { getWebSessionState } from '$lib/stores'

	const webSessionState = getWebSessionState()

	onMount(() => {
		webSessionState.update((state) => ({
			...state,
			pagesVisited: 0,
			timeMounted: Date.now()
		}))
	})
	beforeNavigate(() => {
		if ($webSessionState.timeMounted !== $webSessionState.timeNavigated) {
			webSessionState.update((state) => ({
				...state,
				pagesVisited: state.pagesVisited + 1,
				timeNavigated: Date.now()
			}))
		}
	})
</script>
