<script lang="ts">
	import { onMount } from 'svelte'
	import { wpTrack } from '$lib/analytics/WpPixel'
	import {
		PUBLIC_WOLTAIR_WEB_CODE,
		PUBLIC_IS_FEATURE_BRANCH,
		PUBLIC_ENVIRONMENT
	} from '$env/static/public'

	const WP_CODE = {
		WOLTAIR_CZ: 'WP-ADS-JW1ZT-FYP',
		WOLTAIR_PL: 'WP-ADS-YZKUHHOMT'
	}[PUBLIC_WOLTAIR_WEB_CODE]

	const wpEnabled =
		PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ' &&
		PUBLIC_IS_FEATURE_BRANCH === 'false' &&
		PUBLIC_ENVIRONMENT === 'production' &&
		WP_CODE

	onMount(() => {
		wpTrack('ViewContent', { content_name: `View` })
	})
</script>

<svelte:head>
	{#if wpEnabled}
		{@html `<script defer>
			!(function (w, p, e, v, n, t, s) {
				w['WphTrackObject'] = n
				;(w[n] =
					window[n] ||
					function () {
						;(w[n].queue = w[n].queue || []).push(arguments)
					}),
					(w[n].l = 1 * new Date()),
					(t = p.createElement(e)),
					(s = p.getElementsByTagName(e)[0]),
					(t.async = 1)
				t.src = v
				s.parentNode.insertBefore(t, s)
			})(window, document, 'script', 'https://pixel.wp.pl/w/${WP_CODE}/tr.js', 'wph')
            wph('init', "${WP_CODE}") </script>
		</script>`}
	{/if}
</svelte:head>
