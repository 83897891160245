import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

type WebSession = {
	pagesVisited: number
	timeMounted: number
	timeNavigated: number
	getTimeSinceMounted: () => number
	getTimeSinceNavigated: () => number
}

const defaults: WebSession = {
	pagesVisited: 0,
	timeMounted: Date.now(),
	timeNavigated: Date.now(),
	getTimeSinceMounted() {
		return (Date.now() - this.timeMounted) / 1000
	},
	getTimeSinceNavigated() {
		return (Date.now() - this.timeNavigated) / 1000
	}
}

const WEB_SESSION_MODAL_CTX = 'WEB_SESSION_MODAL_CTX'

export function setWebSessionState(data?: WebSession): Writable<WebSession> {
	const state = writable<WebSession>({
		...defaults,
		...(data && { ...data })
	})
	setContext(WEB_SESSION_MODAL_CTX, state)
	return state
}

export function getWebSessionState(): Writable<WebSession> {
	return getContext<Writable<WebSession>>(WEB_SESSION_MODAL_CTX)
}
