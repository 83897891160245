<script lang="ts">
	import { t } from '$lib/translations'
	import { getExpertContactModalState } from '$lib/stores'
	import { X } from 'lucide-svelte'
	import { page } from '$app/stores'
	import { goto } from '$app/navigation'

	const expertContactModalState = getExpertContactModalState()
</script>

<div
	class="relative flex h-fit min-h-screen w-full flex-col overflow-hidden bg-white pt-2 shadow-xl sm:mx-2 sm:my-8 sm:mt-20 sm:h-fit sm:min-h-0 sm:max-w-[500px] sm:rounded-3xl"
>
	<div class="flex flex-col items-center gap-4 p-4 pt-12 sm:p-8">
		<div class="flex flex-col items-center">
			<img
				src="/images/logos/woltair-favicon.svg"
				alt={t('web.marketplace.needsHelp.modal.title')}
				class="mx-auto h-24 w-24"
			/>
			<h2 class="unstyled text-center text-xl font-semibold">
				{t('web.marketplace.needsHelp.success.title')}
			</h2>
			<p class="unstyled mt-4 text-center text-sm text-w-blue-950/70">
				{t('web.marketplace.needsHelp.success.description')}
			</p>
		</div>
	</div>
	<button
		class="absolute right-0 top-0 z-10 rounded-bl-3xl px-4 py-3.5"
		on:click={() => {
			if ($page.url.searchParams.get('openExpertContact')) {
				const url = new URL(window.location.href)
				url.searchParams.delete('openExpertContact')
				goto(url, { replaceState: true })
			}
			expertContactModalState.update((state) => ({
				...state,
				open: false
			}))
		}}
		><X color="#061c3e" class="hidden sm:block" size={16} />
		<X color="#061c3e" class="sm:hidden" size={24} /></button
	>
</div>
