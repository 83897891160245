<script lang="ts">
	import { string, object } from 'yup'
	import { goto } from '$app/navigation'
	import { sendGTM } from '$lib/analytics'
	import { INSTALLATION_CATEGORY, LEAD_SOURCE } from '$lib/consts'
	import { DOCUMENTS, PRIVACY_POLICY, MARKETPLACE } from '$lib/routes'
	import { getPreLeadModalState } from '$lib/stores'
	import { t } from '$lib/translations'
	import { setLeadCookie, validatePhone } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	const preLeadModalState = getPreLeadModalState()
	let firstValidationDone = false
	let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'

	const formData: Record<string, string> = {
		email: '',
		phone: ''
	}

	const stars = 4.5

	const formErrors: Record<string, string> = {
		email: '',
		phone: ''
	}

	const schema = object({
		email: string().required(t('web.forms.email.required')).email(t('web.forms.email.validate')),
		phone: string()
			.required(t('web.forms.leaveContact.phone.validation'))
			.test(
				'phone-validation',
				t('web.forms.leaveContact.phone.format') || '',
				async function (value) {
					if (!value) return false
					const validationResult = await validatePhone(value)
					return validationResult.isValid
				}
			)
	})

	async function validateField(fieldName: string, value: string) {
		firstValidationDone = true
		try {
			await schema.validateAt(fieldName, { [fieldName]: value })
			formErrors[fieldName] = ''
		} catch (error: any) {
			formErrors[fieldName] = error.message
		}
	}

	async function handleSubmit(e: Event) {
		e.preventDefault()
		try {
			const withWater = $preLeadModalState.params?.heatingPurpose === 'WITH_WATER'
			await Promise.all(
				Object.entries(formData).map(async ([key, value]) => {
					await validateField(key, value.toString() || '')
				})
			)
			if (Object.values(formErrors).some((error) => error)) throw new Error('Validation failed')

			formStep = 'SENDING'
			const response = await fetch('/api/lead/create', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					houseHeatType: $preLeadModalState.params?.houseHeatType,
					zip: $preLeadModalState.params?.zip,
					email: formData.email,
					phone: formData.phone,
					leadSource:
						$preLeadModalState.params?.formType === 'pv'
							? LEAD_SOURCE.PV_BLOG
							: LEAD_SOURCE.HVAC_BLOG,
					...((withWater === true || withWater === false) && {
						waterHeatingWithHouseHeatSource: withWater
					}),
					...($preLeadModalState.params?.annualConsumption && {
						annualElectricityConsumption: $preLeadModalState.params?.annualConsumption
					}),
					installationProductCategory:
						$preLeadModalState.params?.formType === 'pv'
							? INSTALLATION_CATEGORY['FOTOVOLTAIKA']
							: INSTALLATION_CATEGORY['TEPELNE_CERPADLO']
				})
			})
			if (response.ok) {
				formStep = 'SENT'

				const data: any = await response.json()
				const leadUid = data?.leadCreate?.uid || ''

				const gotoUrl = new URL('https://example.com')

				const urlParams = {
					houseHeatType: $preLeadModalState.params?.houseHeatType,
					waterHeatingWithHouseHeatSource: withWater,
					annualElectricityConsumption: $preLeadModalState.params?.annualConsumption,
					formType: $preLeadModalState.params?.formType,
					zip: $preLeadModalState.params?.zip,
					leadUid: leadUid
				}

				Object.entries(urlParams).forEach(([key, value]) => {
					if (value) gotoUrl.searchParams.set(key, value)
				})

				const gtmEventName =
					$preLeadModalState.params?.formType === 'pv'
						? 'bloglead_form_sent_pv'
						: 'bloglead_form_sent_hvac'

				sendGTM(
					gtmEventName,
					{},
					{
						transaction_id: leadUid || '',
						_clear: true
					}
				)
				const event = { detail: { leadUid: leadUid } }
				setLeadCookie(event, $preLeadModalState.params?.formType ?? 'hvac')

				await goto(`/${MARKETPLACE}${gotoUrl.search}`)

				preLeadModalState.update((state) => ({
					...state,
					open: false
				}))
			} else {
				formStep = 'DEFAULT'
				logger.error('components.preLeadModal.error', 'Failed to submit form', response)
			}
		} catch (error: any) {
			logger.error('Oops', error)
			if (error.inner) {
				if (error.inner.length > 0)
					error.inner.forEach((err: any) => {
						formErrors[err.path] = err.message
					})
			}
		}
	}
</script>

{#if $preLeadModalState?.open}
	<div class="fixed left-0 top-0 z-[10000] h-full w-full backdrop-blur-sm">
		<div
			class="fixed top-20 h-full w-full bg-neutral-100 opacity-80"
			tabindex="0"
			role="button"
			on:keydown
			on:click={() => {
				preLeadModalState.update((state) => ({
					...state,
					open: false
				}))
			}}
		/>
		<div class="flex h-screen items-start justify-center overflow-y-auto px-2 py-12 sm:pt-12">
			<div
				class="wrapper relative z-10 mx-auto flex min-h-fit w-[600px] max-w-full flex-col overflow-hidden rounded-3xl bg-white shadow-xl shadow-neutral-600/5"
			>
				<div
					class="relative flex w-full basis-1/2 flex-col items-center justify-center bg-w-blue-950 py-12 pb-8 sm:pt-20"
				>
					<img src="/images/logos/woltair-w-red.svg" alt="Woltair logo" class="w-14" />
					<h2 class="px-4 text-center text-4.5xl font-semibold !leading-snug text-white sm:px-12">
						{$preLeadModalState.params.formType
							? t(`web.forms.preLead.title.${$preLeadModalState.params.formType}`)
							: t(`web.forms.preLead.title`)}
					</h2>
					{#if PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ'}
						<div class="flex flex-wrap items-center justify-center gap-x-4 gap-y-1 text-white">
							<img
								src="/images/logos/refsite-white.webp"
								alt="rating logo"
								class="h-fit object-contain"
								height="32"
								width="80"
							/>
							{#if stars}
								<div class="flex items-center gap-2">
									<div class="flex flex-col gap-1">
										<div class="flex gap-1">
											{#each Array(Math.floor(stars)) as _}
												<img
													src="/images/icons/rating-star.svg"
													class="aspect-square h-[1em]"
													width="16"
													height="16"
													alt="rating"
													loading="lazy"
												/>
											{/each}
											{#if Math.floor(stars) !== Math.ceil(stars)}
												<img
													src="/images/icons/rating-star-half.svg"
													class="aspect-square h-[1em]"
													width="16"
													height="16"
													alt="rating"
													loading="lazy"
												/>
											{/if}
										</div>
									</div>
								</div>
							{/if}
							<span class="flex items-center gap-2 gap-x-3 text-sm underline-offset-2"
								>93 recenzí
							</span>
						</div>
					{/if}
				</div>
				<div class="w-full basis-1/2 px-4 py-8 sm:p-8 sm:pt-6">
					<div class="text-center text-sm">
						<div class="opacity-70">
							<p>{@html t('web.forms.preLead.subtext1')}</p>
							<p>{@html t('web.forms.preLead.subtext2')}</p>
						</div>

						<form class="mt-8 flex flex-col items-start" on:submit={handleSubmit}>
							<label for="email" class="text-sm">{t('web.forms.email.longer')}</label>
							<input
								type="text"
								name="email"
								class="mt-2 w-full rounded-md border-black/10 p-4 text-sm placeholder:opacity-60"
								placeholder={t('web.forms.email.placeholder.example')}
								bind:value={formData.email}
								on:change={() => validateField('email', formData.email)}
								class:border-w-red-500={formErrors.email}
							/>
							{#if formErrors.email}
								<div class="mt-1 text-sm text-w-red-500">{formErrors.email}</div>
							{/if}

							<label for="phone" class="mt-4 text-sm">{t('web.forms.yourPhone')}</label>
							<input
								type="text"
								name="phone"
								class="mt-2 w-full rounded-md border-black/10 p-4 text-sm placeholder:opacity-60"
								placeholder={t('web.forms.phone.placeholder')}
								bind:value={formData.phone}
								on:change={() => validateField('phone', formData.phone)}
								class:border-w-red-500={formErrors.phone}
							/>
							{#if formErrors.phone}
								<div class="mt-1 text-sm text-w-red-500">{formErrors.phone}</div>
							{/if}

							<button
								type="submit"
								class="btn-new relative mt-6 w-full {formData.email &&
								!formErrors.email &&
								formData.phone &&
								!formErrors.phone &&
								firstValidationDone
									? 'btn-new-red'
									: 'btn-new-disabled'}"
							>
								{#if formStep === 'SENDING'}
									<img
										src="/images/icons/loader-2-black.svg"
										class="w-[1.25em] animate-spin"
										alt="Loader icon"
									/>{t('web.forms.preLead.submitting')}
								{:else}
									{t('web.forms.preLead.submit')}
								{/if}
							</button>
						</form>

						<div class="mt-4 text-sm font-light">
							{@html t('web.forms.conditions', {
								link: `/${DOCUMENTS}/${PRIVACY_POLICY}`,
								class: 'underline'
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}
