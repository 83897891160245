<script lang="ts">
	//import cookie from 'cookie'
	import { BROWSER } from 'esm-env'
	import { onMount } from 'svelte'
	import { afterNavigate } from '$app/navigation'
	import { page } from '$app/stores'
	import { saveLeadGTMs } from '$lib/analytics'
	import { WpPixelInit } from '$lib/analytics/WpPixel'
	import DrawerDynamic from '$lib/components/drawer-dynamic/Drawer.svelte'
	import ExitModal from '$lib/components/exit-modal/ExitModal.svelte'
	import FooterDynamic from '$lib/components/footer-dynamic/Footer.svelte'
	import GrantsModal from '$lib/components/grants-modal/GrantsModal.svelte'
	import ThermalLossModal from '$lib/components/house-heat-loss-modal/HouseHeatLossModal.svelte'
	import NavigationDynamic from '$lib/components/navigation-dynamic/Navigation.svelte'
	import NewsletterPopup from '$lib/components/newsletter-popup/NewsletterPopup.svelte'
	import WebsessionObserver from '$lib/components/websession-observer/WebsessionObserver.svelte'
	import { WEB_TRACKING_ID, FACEBOOK_DOMAIN_VERIFICATION, STEP, ORGANIZATION } from '$lib/consts'
	import AppShell from '$lib/modules/layout-root/AppShell.svelte'
	import Wacp from '$lib/modules/layout-root/Wacp.svelte'
	import TranslationLive from '$lib/translation-live/TranslationLive.svelte'
	import { t } from '$lib/translations'
	import { getCookie, setCookie } from '$lib/utils'
	import { removeNBSP } from '$lib/utils'
	import type { LayoutData } from './$types'
	import { PUBLIC_WOLTAIR_WEB_CODE, PUBLIC_ENVIRONMENT } from '$env/static/public'
	import '$lib/styles/app.css'
	import AdvancedFilterModal from '$lib/components/advanced-filter-modal/AdvancedFilterModal.svelte'
	import ExpertContactModal from '$lib/components/expert-contact-modal/ExpertContactModal.svelte'
	import PreLeadModal from '$lib/components/pre-lead-modal/PreLeadModal.svelte'
	import GlobalStoreProvider from '$lib/stores/GlobalStoreProvider.svelte'

	let toggleDrawer: () => void
	let drawer: boolean
	let headless: boolean
	export let data: LayoutData

	let showWacpBanner: boolean | undefined = false
	let showNewsletterPopup: boolean | undefined = false
	function hideWacpBanner() {
		showWacpBanner = false
	}

	if (BROWSER) {
		showWacpBanner = getCookie('CM_BANNER') === 'false' ? false : true
		showNewsletterPopup = getCookie('NWSLTPP') === 'false' ? false : true
		headless = $page.url.searchParams.get('mobile-app') ? true : false
		let cookieSetter = $page.url.searchParams.get('setCookie')
		try {
			if (cookieSetter) {
				const decodedSetCookie = decodeURIComponent(cookieSetter)

				// Step 3: Convert the string to a valid JSON format
				const jsonString = decodedSetCookie
					.replace(/%3A/g, ':') // replace %3A with :
					.replace(/%2C/g, ',') // replace %2C with ,
					.replace(/%7B/g, '{') // replace %7B with {
					.replace(/%7D/g, '}') // replace %7D with }
					.replace(/id:/g, '"id":') // replace id: with "id":
					.replace(/value:/g, '"value":') // replace value: with "value":

				// Parse the JSON string
				const parsedData = JSON.parse(jsonString)

				if (parsedData.id && parsedData.value) {
					setCookie(parsedData.id, parsedData.value, 30)
				}
			}
		} catch (error) {
			logger.debug('cookieSetter.error', 'An error occurred while processing cookieSetter', error)
		}
		const utmParamsEntries = [...$page.url.searchParams.entries()].filter(([key]) =>
			key.startsWith('utm_')
		)
		if (utmParamsEntries.length > 0) {
			saveUTMs(utmParamsEntries)
		}
	}

	async function saveUTMs(utmParamsEntries) {
		let anonymUid = getCookie('CM_SESSION')
		if (!anonymUid) {
			anonymUid = await saveLeadGTMs(utmParamsEntries)
			setCookie('CM_SESSION', anonymUid, 0)
		}
	}

	$: if (BROWSER) headless = $page.url.searchParams.get('mobile-app') ? true : false

	const STATIC_TITLE = ' | Woltair'

	const canonicalParams = ['page', 'limit', STEP]

	let canonicalUrl: string

	$: titleStore =
		$page.data.meta?.title || $page.data.webSettings.metaTitle || t('web.meta.general.title')
	$: ogImage =
		$page.data.meta?.ogImage ||
		$page.data.webSettings.ogImage ||
		`/images/og/${$page.data.organization.lang}/default.webp`

	$: title = removeNBSP(titleStore) + STATIC_TITLE
	$: ogImageURL = ogImage?.includes('http') ? ogImage : `${$page.data.organization.url}${ogImage}`
	$: description =
		$page.data.meta?.description ||
		$page.data.webSettings.metaDescription ||
		t('web.meta.PUBLIC.meta.description')
	$: noindex = $page.data.meta?.noindex ?? false

	const getCanonical = (pageStore: Object, excludeParams: Array, searchParams?: Object) => {
		let parametersString = ''
		let parametersArr: Array = []

		if (searchParams) {
			searchParams.forEach((value, key) => {
				parametersArr.push({ name: key, value: value })
			})
			parametersString = parametersArr
				.filter(({ name }) => excludeParams.includes(name))
				.map(({ name, value }) => `${name}=${value}`)
				.join('&')
		}

		return `${pageStore.url.origin}${pageStore.url.pathname}${
			parametersString.length ? `?${parametersString}` : ''
		}`.replace(/\/$/, '')
	}
	$: canonicalUrl = $page.data.meta?.cannonical ?? getCanonical($page, canonicalParams)

	const scrollSmoothly = (id: string, backToTop: boolean) => {
		if (backToTop) scroll(0, 0)
		setTimeout(() => {
			document.querySelector(id)?.scrollIntoView({
				behavior: 'smooth'
			})
		}, 200)
	}

	onMount(() => {
		canonicalUrl = getCanonical($page, canonicalParams, $page.url?.searchParams)
		analyticsOn = getCookie('CM_ANALYTICS')
		onpopstate = () => {
			if (window.location.hash) {
				scrollSmoothly(window.location.hash, true)
			}
		}
		if (analyticsOn == 'granted' && $page.data.organization.country == 'DE') {
			loadHotjarScript()
		}
	})
	let analyticsOn: string | null = null
	afterNavigate(() => {
		canonicalUrl = getCanonical($page, canonicalParams, $page.url?.searchParams)
		analyticsOn = getCookie('CM_ANALYTICS')

		if (analyticsOn == 'granted' && $page.data.organization.country == 'DE') {
			loadHotjarScript()
		}
		if (showWacpBanner) {
			showWacpBanner = getCookie('CM_BANNER') === 'false' ? false : true
		}

		if (window.location.hash) {
			scrollSmoothly(window.location.hash, true)
		}
	})

	if (BROWSER) {
		analyticsOn = getCookie('CM_ANALYTICS')
	}
	function loadHotjarScript() {
		logger.debug('loadHotjarScript', 'Hotjar init')
		window.hj =
			window.hj ||
			function () {
				;(window.hj.q = window.hj.q || []).push(arguments)
			}
		window._hjSettings = { hjid: 3815002, hjsv: 6 }

		const script = document.createElement('script')
		script.async = true
		script.src =
			'https://static.hotjar.com/c/hotjar-' +
			window._hjSettings.hjid +
			'.js?sv=' +
			window._hjSettings.hjsv
		document.head.appendChild(script)
	}
</script>

<svelte:head>
	{#if PUBLIC_ENVIRONMENT === 'production'}
		{@html `
			<${'script'} type="application/ld+json">
				{
					"@context": "https://schema.org/",
					"@type": "Organization",
					"url": "${ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].webUrl}images/logos/woltair-favicon.webp",
					"logo": "${ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].webUrl}images/logos/woltair-favicon.webp"
				}
			</script>
		`}
	{/if}

	{@html `<${'script'}>
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
		j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      j.async = true;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${WEB_TRACKING_ID[PUBLIC_WOLTAIR_WEB_CODE]}');
  </script>`}
	<title>{title}</title>
	<meta property="og:title" content={title} />
	<meta property="og:image" content={ogImageURL} />
	<meta property="og:site_name" content="Woltair" />
	{#if noindex}
		<meta name="robots" content="noindex, nofollow" />
	{/if}
	{#if description}
		<meta name="description" content={description} />
		<meta property="og:description" content={description} />
	{/if}
	{#if FACEBOOK_DOMAIN_VERIFICATION[PUBLIC_WOLTAIR_WEB_CODE]}
		<meta
			name="facebook-domain-verification"
			content={FACEBOOK_DOMAIN_VERIFICATION[PUBLIC_WOLTAIR_WEB_CODE]}
		/>
	{/if}

	<meta property="og:url" content={$page.url.href} />
	<link rel="canonical" href={canonicalUrl} />
</svelte:head>

<noscript>
	<iframe
		title="google"
		src="https://www.googletagmanager.com/ns.html?id={WEB_TRACKING_ID[PUBLIC_WOLTAIR_WEB_CODE]}"
		height="0"
		width="0"
		style="display:none;visibility:hidden"
	/>
</noscript>

<GlobalStoreProvider>
	<AppShell {drawer}>
		<svelte:fragment slot="supheader">
			<!-- {#if !headless && !$page.data.layout?.headless && PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'}
			<MessageRibbons ribbons={data.ribbons} {drawer} />
		{/if} -->
		</svelte:fragment>
		<svelte:fragment slot="header">
			{#if !headless && !$page.data.layout?.headless}
				<NavigationDynamic {drawer} {toggleDrawer} data={data.navigation} />
			{/if}
		</svelte:fragment>

		<svelte:fragment slot="drawer">
			{#if !headless && !$page.data.layout?.headless}
				<DrawerDynamic bind:drawer bind:toggleDrawer data={data.navigation} />
			{/if}
		</svelte:fragment>
		<slot />
		<svelte:fragment slot="footer">
			{#if !headless && !$page.data.layout?.headless}
				<FooterDynamic data={data.footer} />
			{/if}
		</svelte:fragment>
	</AppShell>

	{#if showWacpBanner && !$page.data.layout?.cookiesBannerHide}
		<Wacp on:message={hideWacpBanner} />
	{/if}
	{#if BROWSER}
		<TranslationLive />
	{/if}

	<ExpertContactModal />
	<PreLeadModal />
	<GrantsModal />
	<NewsletterPopup {showWacpBanner} {showNewsletterPopup} />
	<WebsessionObserver />
	<ExitModal />
	<ThermalLossModal />
	<AdvancedFilterModal />

	<WpPixelInit />
</GlobalStoreProvider>

<style lang="scss">
	:global(body) {
		overflow-x: hidden;
	}
	:global(.translation-live) {
		position: relative;
	}
	:global(.translation-live span:first-child) {
		background-color: #fff;
		background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="utf-8"%3F%3E%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"%3E%3Cpath stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 8-8 8v4h4l8-8m-4-4 2.869-2.869.001-.001c.395-.395.593-.593.821-.667a1 1 0 0 1 .618 0c.228.074.425.272.82.666l1.74 1.74c.396.396.594.594.668.822a1 1 0 0 1 0 .618c-.074.228-.272.426-.668.822h0L16 12.001m-4-4 4 4"/%3E%3C/svg%3E');
		display: block;
		box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.5);
		border-radius: 4px;
		width: 16px;
		height: 16px;
		position: absolute;
		left: -12px;
		right: -12px;
		border: 1px solid #e01f26;
		color: #e01f26;
		fill: #e01f26;
		cursor: pointer;
	}
</style>
