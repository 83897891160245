<script lang="ts">
	import { Tabs } from 'bits-ui'
	import { BROWSER } from 'esm-env'
	import { X } from 'lucide-svelte'
	import HouseHeatLossForm from '$lib/components/house-heat-loss-modal/HouseHeatLossForm.svelte'
	import {
		getHeatLossModalState,
		getMarketplaceDataState,
		getHeatLossModalOpenState
	} from '$lib/stores'
	import { t } from '$lib/translations'
	import { onMount } from 'svelte'
	import { getCookie, setCookie } from '$lib/utils'
	import type { Roof, Window, Facade } from '$lib/stores/definitions/HeatLossModal.store'

	const houseHeatLossModalState = getHeatLossModalState()
	const heatLossModalOpenState = getHeatLossModalOpenState()
	const marketplaceDataState = getMarketplaceDataState()

	let bgUrl = '/images/thermal-loss/thermal-loss-low.webp'

	let tabValue: 'LOW' | 'MEDIUM' | 'HIGH' | 'CALCULATED' = 'LOW'

	const predefinedHeatLoss = {
		LOW: 4.2,
		MEDIUM: 5.2,
		HIGH: 9.1
	}

	const options = {
		LOW: {
			window: 'TRIPLE' as Window,
			roof: '30' as Roof,
			facade: '30' as Facade
		},
		MEDIUM: {
			window: 'DOUBLE' as Window,
			roof: '20' as Roof,
			facade: '25' as Facade
		},
		HIGH: {
			window: 'WOODEN' as Window,
			roof: '10' as Roof,
			facade: '0' as Facade
		}
	}

	heatLossModalOpenState.subscribe((state) => {
		if (BROWSER) {
			if (state === true) {
				const scrollX = document.querySelector('html')?.scrollTop || 0
				window.onscroll = () => window.scrollTo(0, scrollX)

				bgUrl = '/images/thermal-loss/thermal-loss-low.webp'
				tabValue = $houseHeatLossModalState.valueType || 'LOW'

				if (
					tabValue !== 'CALCULATED' &&
					$houseHeatLossModalState.value !== $marketplaceDataState.heatLoss
				) {
					determineHouseHeatLoss(tabValue || 'LOW')
				}
			} else {
				window.onscroll = (event) => event
			}
		}
	})

	let calculatedHeatLoss = $houseHeatLossModalState.value
	let checkingHeatLoss = false

	onMount(() => {
		const dataCookie = getCookie('HEAT_LOSS_DATA')

		if (dataCookie) {
			const data = JSON.parse(dataCookie)
			houseHeatLossModalState.update((state) => ({
				...state,
				...(data.valueType && { valueType: data.valueType }),
				...(data.value && { value: data.value }),
				formData: {
					...(data.formData?.floorSquare && { floorSquare: data.formData.floorSquare }),
					...(data.formData?.facade && { facade: data.formData.facade }),
					...(data.formData?.roof && { roof: data.formData.roof }),
					...(data.formData?.window && { window: data.formData.window })
				}
			}))
		}
	})

	async function determineHouseHeatLoss(tab: 'HIGH' | 'MEDIUM' | 'LOW') {
		checkingHeatLoss = true

		try {
			const heatLoss = await $houseHeatLossModalState.calculate({
				zip: $marketplaceDataState.cityName,
				floorSquare: $marketplaceDataState.floorSquare,
				window: options[tab].window,
				roof: options[tab].roof,
				facade: options[tab].facade
			})

			calculatedHeatLoss = heatLoss || predefinedHeatLoss[tab]
			setCookie('HEAT_LOSS_TYPE', tab, 30)

			checkingHeatLoss = false
		} catch (error) {
			calculatedHeatLoss = predefinedHeatLoss[tab]
			checkingHeatLoss = false
		}
	}

	function updateHeatLoss(tab: 'LOW' | 'MEDIUM' | 'HIGH') {
		houseHeatLossModalState.update((state) => ({
			...state,
			value: calculatedHeatLoss,
			valueType: tab,
			formData: {
				roof: options[tab].roof,
				window: options[tab].window,
				facade: options[tab].facade,
				floorSquare: $marketplaceDataState.floorSquare,
				zip: $marketplaceDataState.cityName
			}
		}))

		setCookie('HEAT_LOSS_DATA', JSON.stringify($houseHeatLossModalState), 30)
		$heatLossModalOpenState = false
	}
</script>

{#if $heatLossModalOpenState}
	<div
		class="hide-scrollbar animate-opacity fixed left-0 top-0 z-[10000] flex h-full w-full items-start justify-center overflow-y-scroll bg-neutral-300/20 backdrop-blur-sm"
	>
		<div
			class="absolute h-full w-full"
			role="none"
			on:click={() => {
				$heatLossModalOpenState = false
			}}
		/>
		<div
			class="animate-in relative mt-2 flex min-h-screen w-full max-w-[600px] flex-col overflow-hidden bg-white pt-12 shadow-lg sm:mx-2 sm:my-8 sm:mt-20 sm:min-h-0 sm:rounded-3xl"
		>
			<div class="px-4 text-center sm:px-12">
				<h2 class="unstyled text-xl font-medium">{t('web.modals.thermalLoss.title')}</h2>
				<p class="text-sm text-w-blue-950/70">
					{t('web.modals.thermalLoss.text')}
				</p>
			</div>
			<div class="flex-grow pt-2">
				{#if bgUrl && bgUrl !== '/'}
					<img
						src={bgUrl}
						alt="background"
						class="absolute bottom-0 -z-10 w-full object-contain sm:hidden"
					/>
				{/if}
				<Tabs.Root value={tabValue}>
					<Tabs.List
						class="mx-2 mb-2 grid grid-cols-2 gap-1 rounded-lg p-1 text-w-blue-950 sm:mx-4 sm:grid-cols-4 sm:bg-neutral-200/70"
					>
						<Tabs.Trigger
							value="LOW"
							data-testid="heatlosstrigger-low"
							on:click={() => {
								determineHouseHeatLoss('LOW')
								bgUrl = '/images/thermal-loss/thermal-loss-low.webp'
							}}
							class="col-span-1 w-full rounded-md border border-neutral-200 bg-transparent bg-white py-2 active:bg-w-red-300 data-[state=active]:border-w-blue-950 data-[state=active]:bg-w-blue-950 data-[state=active]:text-white sm:border-none sm:bg-transparent sm:data-[state=active]:bg-white sm:data-[state=active]:text-w-blue-950"
							>{t('web.modals.thermalLoss.tabs.low')}</Tabs.Trigger
						>
						<Tabs.Trigger
							value="MEDIUM"
							data-testid="heatlosstrigger-medium"
							on:click={() => {
								determineHouseHeatLoss('MEDIUM')
								bgUrl = '/images/thermal-loss/thermal-loss-medium.webp'
							}}
							class="col-span-1 w-full rounded-md border border-neutral-200 bg-transparent bg-white py-2 data-[state=active]:border-w-blue-950 data-[state=active]:bg-w-blue-950 data-[state=active]:text-white sm:border-none sm:bg-transparent sm:data-[state=active]:bg-white sm:data-[state=active]:text-w-blue-950"
							>{t('web.modals.thermalLoss.tabs.medium')}</Tabs.Trigger
						>
						<Tabs.Trigger
							value="HIGH"
							data-testid="heatlosstrigger-high"
							on:click={() => {
								determineHouseHeatLoss('HIGH')
								bgUrl = '/images/thermal-loss/thermal-loss-high.webp'
							}}
							class="col-span-1 w-full rounded-md border border-neutral-200 bg-transparent bg-white py-2 data-[state=active]:border-w-blue-950 data-[state=active]:bg-w-blue-950 data-[state=active]:text-white sm:border-none sm:bg-transparent sm:data-[state=active]:bg-white sm:data-[state=active]:text-w-blue-950"
							>{t('web.modals.thermalLoss.tabs.high')}</Tabs.Trigger
						>
						<Tabs.Trigger
							value="CALCULATED"
							on:click={() => {
								bgUrl = '/'
							}}
							class="col-span-1 w-full rounded-md border border-neutral-200 bg-transparent bg-white py-2 data-[state=active]:border-w-blue-950 data-[state=active]:bg-w-blue-950 data-[state=active]:text-white sm:border-none sm:bg-transparent sm:data-[state=active]:bg-white sm:data-[state=active]:text-w-blue-950"
							>{t('web.modals.thermalLoss.tabs.calculate')}</Tabs.Trigger
						>
					</Tabs.List>
					<Tabs.Content value="LOW">
						<div
							class="mt-auto flex flex-col justify-end bg-[length:auto_100%] bg-bottom bg-no-repeat sm:min-h-[496px] sm:bg-[url('/images/thermal-loss/thermal-loss-low.webp')] sm:bg-[length:100%_auto]"
						>
							<div class="mx-4 my-6 sm:mx-6">
								<div
									class="mb-4 flex w-full flex-col gap-1 rounded-xl border border-neutral-300 bg-neutral-100 p-5 sm:w-fit sm:border-none sm:bg-white"
								>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.windows')}:</span>
										{t('web.woltadvisor.TRIPLE')}
									</p>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.roof')}:</span> 30 cm
									</p>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.facade')}:</span> 30 cm
									</p>
								</div>
								<button
									class="btn-new btn-new-blue w-full"
									on:click={() => {
										updateHeatLoss('LOW')
									}}
									data-testid="updateHeatLoss"
								>
									{#if checkingHeatLoss}
										<div class="loader" />
									{:else}{t('web.modals.thermalLoss.button', {
											value: (calculatedHeatLoss ?? predefinedHeatLoss.LOW).toString()
										})}
									{/if}</button
								>
							</div>
						</div>
					</Tabs.Content>
					<Tabs.Content data-testid="medium" value="MEDIUM">
						<div
							class="flex flex-col justify-end bg-[length:auto_100%] bg-bottom bg-no-repeat sm:min-h-[496px] sm:bg-[url('/images/thermal-loss/thermal-loss-medium.webp')] sm:bg-[length:100%_auto]"
						>
							<div class="mx-4 my-6 sm:mx-6">
								<div
									class="mb-4 flex w-full flex-col gap-1 rounded-xl border border-neutral-300 bg-neutral-100 p-5 sm:w-fit sm:border-none sm:bg-white"
								>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.windows')}:</span>
										{t('web.woltadvisor.DOUBLE')}
									</p>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.roof')}:</span> 20 cm
									</p>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.facade')}:</span> 25 cm
									</p>
								</div>
								<button
									class="btn-new btn-new-blue w-full"
									on:click={() => {
										updateHeatLoss('MEDIUM')
									}}
									data-testid="updateHeatLoss"
								>
									{#if checkingHeatLoss}
										<div class="loader" />
									{:else}
										{t('web.modals.thermalLoss.button', {
											value: (calculatedHeatLoss ?? predefinedHeatLoss.MEDIUM).toString()
										})}
									{/if}</button
								>
							</div>
						</div>
					</Tabs.Content>
					<Tabs.Content value="HIGH">
						<div
							class="flex flex-col justify-end bg-[length:auto_100%] bg-bottom bg-no-repeat sm:min-h-[496px] sm:bg-[url('/images/thermal-loss/thermal-loss-high.webp')] sm:bg-[length:100%_auto]"
						>
							<div class="mx-4 my-6 sm:mx-6">
								<div
									class="mb-4 flex w-full flex-col gap-1 rounded-xl border border-neutral-300 bg-neutral-100 p-5 sm:w-fit sm:border-none sm:bg-white"
								>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.windows')}:</span>
										{t('web.woltadvisor.WOODEN')}
									</p>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.roof')}:</span> 10 cm
									</p>
									<p class="unstyled">
										<span class="font-bold">{t('web.woltadvisor.facade')}:</span>
										{t('web.forms.thermalInsulation.no.longer')}
									</p>
								</div>
								<button
									class="btn-new btn-new-blue w-full"
									on:click={() => {
										updateHeatLoss('HIGH')
									}}
									data-testid="updateHeatLoss"
									>{#if checkingHeatLoss}
										<div class="loader" />
									{:else}
										{t('web.modals.thermalLoss.button', {
											value: (calculatedHeatLoss ?? predefinedHeatLoss.HIGH).toString()
										})}
									{/if}
								</button>
							</div>
						</div>
					</Tabs.Content>
					<Tabs.Content value="CALCULATED">
						<div class="flex">
							<HouseHeatLossForm />
						</div>
					</Tabs.Content>
				</Tabs.Root>
			</div>

			<button
				class="absolute right-0 top-0 rounded-bl-3xl px-4 py-3.5"
				on:click={() => {
					$heatLossModalOpenState = false
				}}
				><X color="#061c3e" class="hidden sm:block" size={16} />
				<X color="#061c3e" class="sm:hidden" size={24} /></button
			>
		</div>
	</div>
{/if}

<style>
	.animate-in {
		animation: slide-in 0.15s ease-out both;
	}

	.animate-opacity {
		animation: opacity-in 0.15s ease-out both;
	}

	@keyframes slide-in {
		0% {
			transform: translateY(-20%) scale(0.5);
			opacity: 0;
		}
		100% {
			transform: translateY(0) scale(1);
			opacity: 1;
		}
	}
	.loader {
		border: 4px solid rgba(255, 255, 255, 0.1);
		border-left-color: #fff;
		border-radius: 50%;
		width: 1.5em;
		height: 1.5em;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes opacity-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
